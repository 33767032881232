import { useState } from "react";
import "../../../../assets/App.css";
import "../../../../assets/Modales.css";
import "../../../../assets/Repositorio.css";
import "../../../../assets/Table.css";
import { format } from "date-fns";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from "@mui/material";
import icon_warningOrange from "../../../../components/icons/icon_warningOrange.svg";
import icon_infoBig from "../../../../components/icons/icon_infoBig.svg";
import PrimaryButton, { SecondaryButton } from "../../../../components/Buttons/Botones";
import { useMsal } from "@azure/msal-react";
import {
    OpcionesDocumentos,
    OpcionesClasificaciones,
} from "./Opciones";
import {
    saveBitacora,
    cargararchivo,
} from "../../../../utils/queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { DatePicker } from "../../../../components/DatePicker/DatePicker";
import BotonCarga from "../../../../components/Buttons/BotonCarga";

export default function NuevoRegistroBitacora({ setSnackData, sucursal, deshabilitaInputs, ...props }) {
    const [clasificacion, setClasificacion] = useState(0);
    const [fecha, setFecha] = useState(null);
    const [tipoDocumento, setTipoDocumento] = useState(0);
    const [comentario, setComentario] = useState("");
    const [fileName, setFileName] = useState("");
    const [base64, setBase64] = useState(null);
    const [confirmacion, setConfirmacion] = useState(false);
    const [errorTipo, setErrorTipo] = useState(false);
    const [errorDoc, setErrorDoc] = useState(false);
    const [errorClasificacion, setErrorClasificacion] = useState(false);
    const [errorTipoDoc, setErrorTipoDoc] = useState(false);
    const [accionAceptar, setAccionAceptar] = useState(false);
    const { accounts } = useMsal();

    const [guardaRegistro] = useMutation(saveBitacora, {
        onCompleted: () => {
            setConfirmacion(false);
            setAccionAceptar(false);
            clearAll();
            document.getElementById("recargaTabla").click();
            setSnackData({
                abrir: true,
                mensaje: "Registro agregado correctamente.",
                severidad: "success",
            });
        },
        onError: (error) => {
            setConfirmacion(false);
            setAccionAceptar(false);
            //console.log("Error bitacora: " + error.message)
            setSnackData({
                abrir: true,
                mensaje: "No se pudo guardar el registro, Favor reintente.",
                severidad: "error",
            });
        }
    });

    const [cargarArchivo] = useLazyQuery(cargararchivo, {
        onCompleted: (data) => {
            guardaRegistro({
                variables: {
                    folio_solicitud: props.data.fkFun,
                    rutPrestador: null,
                    razonSocial: null,
                    comentario: comentario || null,
                    createdby: accounts[0].name,
                    fechaCompromiso: fecha,
                    tipoDoc: parseInt(tipoDocumento),
                    documento: data.crearArchivo.documentId,
                    clasificacion: parseInt(clasificacion),
                    sucursal: sucursal
                }
            });
        },
        onError: (error) => {
            //console.log("error carga archivo: " + error);
            setConfirmacion(false);
            setAccionAceptar(false);
            setSnackData({
                abrir: true,
                mensaje: "No se pudo subir el archivo a Gestor Documental, Favor reintente.",
                severidad: "error",
            });
        }
    })

    function validaDatos() {
        let error = false;
        if (base64 && tipoDocumento === 0) {
            setErrorTipo(true);
            error = true;
        }

        if (base64 === null && tipoDocumento !== 0) {
            setErrorDoc(true);
            error = true;
        }
        if (clasificacion === 0) {
            setErrorClasificacion(true);
            error = true;
        }

        if (!error) {
            setConfirmacion(true);
        }
    }

    const comentarioIngresado = (event) => {
        if (event.target.value.length <= 3000) {
            setComentario(event.target.value);
        }
    };

    function clearAll() {
        setClasificacion(0);
        setErrorDoc(false);
        setErrorClasificacion(false);
        setFecha(null);
        setTipoDocumento(0);
        setComentario("");
        setFileName("");
        setBase64(null);
        setErrorTipo(false);
        document.getElementById("inputArchivoAdicional").value = [];
    }

    function transformarBase64(archivo) {
        var reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = ({ target }) => {
            const base64WithoutPrefix = target.result.substr(
                `data:${archivo.type};base64,`.length
            );
            setBase64(base64WithoutPrefix);
        };
    }

    const detectaCambioArchivo = (event) => {
        if (
            // 5MB
            (event.target.files[0].size <= 5242880) &&
            (
                event.target.files[0].type === "application/pdf" ||
                event.target.files[0].type === "image/jpeg" ||
                event.target.files[0].type === "image/png" ||
                event.target.files[0].type === "image/gif" /*||
          event.target.files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          event.target.files[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/
            )
        ) {
            setFileName(event.target.files[0].name);
            transformarBase64(event.target.files[0]);
            setErrorDoc(false);
        } else {
            setErrorTipoDoc(true);
            document.getElementById("inputArchivoAdicional").value = [];
        }
    };

    function cerrarTipoDoc() {
        setErrorTipoDoc(false);
    }

    function cargaArchivo() {
        setAccionAceptar(true);
        if (base64 === null) {
            guardaRegistro({
                variables: {
                    folio_solicitud: props.data.fkFun,
                    rutPrestador: null,
                    razonSocial: null,
                    comentario: comentario,
                    createdby: accounts[0].name,
                    fechaCompromiso: fecha,
                    tipoDoc: null,
                    documento: null,
                    clasificacion: parseInt(clasificacion),
                    sucursal: sucursal
                }
            });
        } else {
            cargarArchivo({
                variables: {
                    idProceso: "11",
                    idTipoTrans: "2003",
                    idTipoDoc: tipoDocumento,
                    folioTrans: props.data.fkFun.toString(),
                    fechaTrans: format(new Date(), "MM-dd-yyyy"),
                    rutBenef: props.data.rutPaciente.toString(),
                    folioDoc: props.data.fkFun.toString(),
                    nombreDoc: fileName.toString(),
                    base64: base64.toString(),
                },
            });
        }

    }

    return (
        <div className="container">
            <Grid
                sx={{ padding: "0px 24px 20px 14px", margin: "0", width: "100%" }}
                container
                spacing={2}
                id="paciente"
            >
                <Grid item xs={12}>
                    <label className="title">Nuevo registro</label>
                </Grid>

                <Grid item xs={6}>
                    <label htmlFor="telefono">Clasificación</label>
                    <OpcionesClasificaciones
                        testid="selectClasificacion"
                        value={clasificacion}
                        disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? true : false}
                        error={errorClasificacion ? true : undefined}
                        setValue={(value) => {
                            setClasificacion(value);
                            setErrorClasificacion(false);
                        }}
                    />
                    {errorClasificacion ? (
                        <div className="text-danger">Debe elegir una clasificación</div>
                    ) : null}
                </Grid>
                <Grid item xs={6}>
                    <label htmlFor="telefono">Fecha compromiso (opcional)</label>
                    <DatePicker
                        id="fechaCompromiso"
                        testid="fechaCompromiso"
                        value={fecha}
                        disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? true : false}
                        onChange={(f) => {
                            setFecha(f);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <label>Tipo documento (opcional)</label>
                    <OpcionesDocumentos
                        testid="selectDocumento"
                        value={tipoDocumento}
                        disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? 1 : 0}
                        error={errorTipo ? true : undefined}
                        setValue={(value) => {
                            setTipoDocumento(value);
                            setErrorTipo(false);
                        }}
                    />
                    {errorTipo ? (
                        <div className="text-danger">
                            Debe elegir un tipo con documento adjunto!
                        </div>
                    ) : null}
                </Grid>
                <Grid item xs={6}>
                    <label htmlFor="telefono">Adjuntar documento (opcional)</label>
                    <input
                        id="inputArchivoAdicional"
                        data-testid="inputArchivoTest"
                        type="file"
                        className="form-control"
                        placeholder="Documento.pdf"
                        name="file"
                        disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? 1 : 0}
                        onChange={detectaCambioArchivo}
                    />
                    <Dialog
                        aria-labelledby="unstyled-modal-title"
                        aria-describedby="unstyled-modal-description"
                        open={errorTipoDoc}
                        onClose={cerrarTipoDoc}
                        sx={{
                            "& .MuiBackdrop-root": {
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                        }}
                    >
                        <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
                            <img alt="img2" src={icon_warningOrange}></img>
                        </Grid>
                        <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
                            Problemas con el documento
                        </DialogTitle>
                        <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
                            <DialogContentText
                                alignContent="center"
                                sx={{ m: 1, textAlign: "center" }}
                            >
                                <label>
                                    El documento no es válido. Solo puedes agregar archivos:
                                    PDF, JPEG, PNG, GIF con un peso máximo de 5MB
                                </label>
                            </DialogContentText>

                            <DialogActions>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    {" "}
                                    <PrimaryButton
                                        data-testid="aceptarError"
                                        variant=" text"
                                        color="default"
                                        onClick={cerrarTipoDoc}
                                    >
                                        Aceptar
                                    </PrimaryButton>
                                </Grid>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                    {errorDoc ? (
                        <div className="text-danger">
                            Debe subir un documento adjunto!
                        </div>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <label>Comentario (opcional)</label>
                    <textarea
                        data-testid="comentarioOpcional"
                        id="comentario"
                        cols="30"
                        rows="4"
                        className="form-control"
                        placeholder="Cuéntanos tus observaciones (tentativo)"
                        maxLength={3000}
                        value={comentario}
                        disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? 1 : 0}
                        onChange={(event) => {
                            comentarioIngresado(event);
                        }}
                    ></textarea>
                    <Grid item xs={12}>
                        <label className="text-end">{comentario.length}/3000</label>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <SecondaryButton
                            type="button"
                            disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? true : false}
                            onClick={clearAll}
                        >
                            Limpiar datos
                        </SecondaryButton>

                        <PrimaryButton
                            type="button"
                            data-testid="botonGuardarBit"
                            disabled={deshabilitaInputs || props.data.fkEstadoSolicitudIsapre === 6 ? true : false}
                            onClick={() => validaDatos()}
                        >
                            Guardar
                        </PrimaryButton>
                    </Grid>

                    <Dialog
                        aria-labelledby="unstyled-modal-title"
                        aria-describedby="unstyled-modal-description"
                        open={confirmacion}
                        sx={{
                            "& .MuiBackdrop-root": {
                                backgroundColor: "rgba(0, 0, 0, 0.3)",
                            },
                        }}
                    >
                        <Grid sx={{ m: 1, textAlign: "center", padding: "20px 0 0" }}>
                            <img alt="img2" src={icon_infoBig}></img>
                        </Grid>
                        <DialogTitle className="tituloModal" sx={{ padding: "0" }}>
                            Nuevo registro
                        </DialogTitle>
                        <DialogContent sx={{ padding: "0 20px", width: "540px" }}>
                            <DialogContentText
                                alignContent="center"
                                sx={{ m: 1, textAlign: "center" }}
                            >
                                Vas a añadir un nuevo registro a la bitácora
                            </DialogContentText>

                            <DialogActions>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    {" "}
                                    <SecondaryButton
                                        variant=" text"
                                        color="default"
                                        disabled={accionAceptar}
                                        onClick={() => setConfirmacion(false)}
                                    >
                                        Cancelar
                                    </SecondaryButton>
                                    <BotonCarga
                                        id="guardarBit"
                                        testid="guardarBit"
                                        label="Añadir registro"
                                        toDo={() => cargaArchivo()}
                                        loading={accionAceptar}
                                    />
                                </Grid>
                            </DialogActions>
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
        </div>
    );
}