import { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { cargararchivo2, generarFormulario1, getsolicitudxfk, getCiudades, guardaform } from "../../utils/queries";
import { Grid } from "@mui/material";
import { format } from 'date-fns';
import { IconOutlined } from "../Buttons/Botones";
import { IconView } from "../icons/iconosEsencial";
import { PreviewFile } from "../Dialog/PreviewFile";
import base64toBlob from "../../utils/Base64";
import { parseFecha } from "../../utils/fechaUtils";
import { formatRut } from "chilean-rutify";

export const GenerarF1Bandeja = ({ fkFun, correo, sucursal, upd }) => {
    const [modalGF1, setModalGF1] = useState(false);
    const [urlSPO, setUrlSPO] = useState(null);

    const [obtenDataCaso, { data, loading: loadingDB }] = useLazyQuery(getsolicitudxfk, {
        variables: { fkFun: fkFun },
        fetchPolicy: "network-only",
        onCompleted: (resp) => {
            const region = resp.getSolicitudesGCbyFkfun[0].codLocalidadPaciente.substr(0, 2);
            obtenerCiudades({
                variables: { idReg: region },
            });
        }
    });

    const [obtenerCiudades, { loading: loadingCiudades }] = useLazyQuery(getCiudades, {
        onCompleted: (res) => {
            const ciudad = data.getSolicitudesGCbyFkfun[0].codLocalidadPaciente.substr(0, 3);
            const nombreCiudad = res.getCiudades.find((ciu) => ciu.idCiu === ciudad);
            const fechaRecepcion = new Date(data.getSolicitudesGCbyFkfun[0].fechaRecepcionSolicitud);
            obtenerF1Plantilla({
                variables: {
                    id: data.getSolicitudesGCbyFkfun[0].fkFun.toString(),
                    nombreBeneficiario: data.getSolicitudesGCbyFkfun[0].nombreCotizante,
                    rutBeneficiario: formatRut(data.getSolicitudesGCbyFkfun[0].rutCotizante),
                    nombrePaciente: data.getSolicitudesGCbyFkfun[0].nombrePaciente + " " + data.getSolicitudesGCbyFkfun[0].apellido1Paciente + " " + data.getSolicitudesGCbyFkfun[0].apellido2Paciente,
                    rutPaciente: formatRut(data.getSolicitudesGCbyFkfun[0].rutPaciente),
                    dirPaciente: data.getSolicitudesGCbyFkfun[0].dirPaciente + (data.getSolicitudesGCbyFkfun[0].dir2Paciente ? " " + data.getSolicitudesGCbyFkfun[0].dir2Paciente + "." : "."),
                    ciudadPaciente: nombreCiudad.nomCiu,
                    telPaciente: data.getSolicitudesGCbyFkfun[0].telPaciente.toString(),
                    telOfiPaciente: " - ",
                    celPaciente: data.getSolicitudesGCbyFkfun[0].celPaciente.toString(),
                    diagnosticoGes: data.getSolicitudesGCbyFkfun[0].fkProblemaSalud ? data.getSolicitudesGCbyFkfun[0].fkProblemaSalud + " - " + data.getSolicitudesGCbyFkfun[0].nombrepat.GlosaSis : null,
                    diagnosticoCaec: data.getSolicitudesGCbyFkfun[0].diagnosticoCaec,
                    tratamiento: data.getSolicitudesGCbyFkfun[0].tratamiento,
                    medicoTratante: data.getSolicitudesGCbyFkfun[0].nombreMedicoTratante,
                    especialidadMedTrat: data.getSolicitudesGCbyFkfun[0].especialidadMedicoTratante,
                    razonSocialPrestador: data.getSolicitudesGCbyFkfun[0].razonSocialPrestador,
                    hospSi: data.getSolicitudesGCbyFkfun[0].declaraHospitalizacion ? "X" : "",
                    hospNo: data.getSolicitudesGCbyFkfun[0].declaraHospitalizacion ? "" : "X",
                    fechaDesdeHosp: data.getSolicitudesGCbyFkfun[0].fechaHospitalizacionDesde ? format(parseFecha(data.getSolicitudesGCbyFkfun[0].fechaHospitalizacionDesde), "dd/MM/yyyy") : "-",
                    fechaHastaHosp: data.getSolicitudesGCbyFkfun[0].fechaHospitalizacionHasta ? format(parseFecha(data.getSolicitudesGCbyFkfun[0].fechaHospitalizacionHasta), "dd/MM/yyyy") : "-",
                    razonSocialHosp: data.getSolicitudesGCbyFkfun[0].razonSocialInstitucionHopsitalizacion,
                    trasSi: data.getSolicitudesGCbyFkfun[0].declaraTraslado ? "X" : "",
                    trasNo: data.getSolicitudesGCbyFkfun[0].declaraTraslado ? "" : "X",
                    origenTraslado: data.getSolicitudesGCbyFkfun[0].origenDestinoTraslado || "-",
                    destinoTraslado: data.getSolicitudesGCbyFkfun[0].destinoTraslado || "-",
                    nombreRepresentante: data.getSolicitudesGCbyFkfun[0].nombreRepresentante + " " + data.getSolicitudesGCbyFkfun[0].apellido1Representante + " " + data.getSolicitudesGCbyFkfun[0].apellido2Representante,
                    rutRepresentante: formatRut(data.getSolicitudesGCbyFkfun[0].rutRepresentante),
                    tipoRepresentante: "",
                    telRepresentante: data.getSolicitudesGCbyFkfun[0].telRepresentante.toString(),
                    telOfiRepresentante: " - ",
                    celRepresentante: data.getSolicitudesGCbyFkfun[0].celRepresentante.toString(),
                    sucursal: sucursal,
                    createUser: data.getSolicitudesGCbyFkfun[0].createdUser,
                    fechaRecepSolicitud: format(fechaRecepcion, "dd/MM/yyyy"),
                    horaRecepSolicitud: format(fechaRecepcion, "HH:mm:ss"),
                },
            });
        }
    });

    const [obtenerF1Plantilla, { loading: loadF1 }] = useLazyQuery(generarFormulario1, {
        fetchPolicy: "cache-and-network",
        onCompleted: (res) => {
            const file = base64toBlob(res.generarFormulario1.base64, null);
            const fileURL = URL.createObjectURL(file);
            subirForm1(file);
            setUrlSPO(fileURL);
        }
    });

    const [subirDoc, { loading: loadF1G }] = useMutation(cargararchivo2);

    const [guardarFormBD, { loading: loadF1DB }] = useMutation(guardaform);

    const subirForm1 = (file) => {
        const fileRead = new FileReader();
        fileRead.readAsDataURL(file);
        fileRead.onload = ({ target }) => {
            subirDoc({
                variables: {
                    idProceso: "11",
                    idTipoTrans: "2001",
                    idTipoDoc: "2001",
                    folioTrans: fkFun.toString(),
                    fechaTrans: format(new Date(), "MM-dd-yyyy"),
                    rutBenef: data.getSolicitudesGCbyFkfun[0].rutPaciente,
                    folioDoc: fkFun.toString(),
                    nombreDoc: "Formulario1_SF_" + data.getSolicitudesGCbyFkfun[0].rutPaciente + ".pdf",
                    base64: target.result.substr(`data:${file.type};base64,`.length)
                },
                onCompleted: (gd) => {
                    guardarFormBD({
                        variables: {
                            link: gd.crearArchivo2.documentId,
                            tipoDoc: "form1",
                            folio: fkFun,
                        }
                    })
                }
            })
        }
    }

    const generarF1 = () => {
        obtenDataCaso();
        setModalGF1(true);
    };

    const cerrarModal = () => {
        setModalGF1(false);
        upd();
    };
    return (
        <Grid>
            <IconOutlined
                onClick={generarF1}
                data-testid="prevFileForm"
            >
                <IconView />
            </IconOutlined>
            <PreviewFile
                openDialog={modalGF1}
                handleClose={cerrarModal}
                urlSPO={urlSPO}
                loading={loadingDB || loadingCiudades || loadF1}
                uploadG={loadF1G}
                uploadDB={loadF1DB}
            />
        </Grid>
    )
}