import { useState, useEffect } from "react";
import "../../../assets/Main.css";
import { useLazyQuery, useQuery, NetworkStatus } from "@apollo/client";
import { getsolicitudxfk, consultabeneficiarios } from "../../../utils/queries";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Box, Typography, Tab, Tabs } from "@mui/material";
import { InformacionDelCaso } from "../InformacionDelCaso/InformacionDelCaso";
import { DetaTabla } from "./gescaeinfo";
import { TabPanel, a11yProps } from "./components/tabs";
import GES from "../CanastaPrestadores/GES";
import CAEC from "../CanastaPrestadores/CAEC";
import GesCaeHitos from "../Hitos/GesCaeHitos";
import GesCaeBitacora from "../Bitacora/GesCaeBitacora";
import SkeletonCaso from "../../../components/Skeleton/SkeletonCaso";
import { Link, useNavigate } from "react-router-dom";
import { isContralor } from "../../../utils/hasPermisions";

const Main = ({ rol, sucursal, correo }) => {
  const navigate = useNavigate();
  if (!sessionStorage.getItem("fkFun")) {
    navigate("/TodosLosCasos");
  }
  const [tabValue, setTabValue] = useState(0);
  const [deshabilitaInputs, setCasoInvalido] = useState(false);
  const [preexistencias, setPreexistencias] = useState(null);
  const [dataCaso, setDataCaso] = useState({});
  const { loading, error, refetch, networkStatus, startPolling, stopPolling } = useQuery(getsolicitudxfk, {
    variables: { fkFun: parseInt(sessionStorage.getItem("fkFun")) },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: (benef) => {
      setDataCaso(benef.getSolicitudesGCbyFkfun[0]);
      if (benef.getSolicitudesGCbyFkfun[0].pacienteVigente) {
        verificaBenef({
          variables: {
            rut: benef.getSolicitudesGCbyFkfun[0].rutPaciente
          }
        });
      } else {
        setCasoInvalido(true);
      }
    }
  });

  const [verificaBenef] = useLazyQuery(consultabeneficiarios, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      if (!res.getBeneficiariosbyRut || res.getBeneficiariosbyRut.length === 0) {
        setCasoInvalido(true);
      } else {
        const prex = res.getBeneficiariosbyRut.find(benef => benef.rut === dataCaso.rutPaciente);
        setPreexistencias(prex.illnessAssessment);
      }
    }
  });

  useEffect(() => {
    startPolling(60000);
    return () => stopPolling();
  }, [startPolling, stopPolling]);

  if (loading && !(networkStatus === NetworkStatus.refetch || networkStatus === NetworkStatus.poll || networkStatus === NetworkStatus.ready))
    return (
      <div data-testid="loader">
        <SkeletonCaso />
      </div>
    );

  if (error) return <pre data-testid="errorMessage">{error.message}</pre>;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className="">
        <div className="container Main" data-testid="main-container">
          <label className="titulo1"> Caso N°: {dataCaso.fkFun}</label>
          <Breadcrumbs
            sx={{ paddingBottom: "14px", fontFamily: "interstate" }}
            aria-label="breadcrumb"
            className="breadcrum"
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              underline="always"
              color="inherit"
              to="/TodosLosCasos"
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "400",
                margin: "0px,8px",
                cursor: "pointer",
              }}
            >
              Bandeja de casos médicos Ges - Caec
            </Link>
            <Typography
              sx={{
                color: "#364855",
                fontSize: "14px",
                fontWeight: "700",
                marginBottom: "-1px",
                lineHeight: "24px",
              }}
            >
              N° de caso: {dataCaso.fkFun}
            </Typography>
          </Breadcrumbs>
          <Box
            className="nav"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              padding: "26px 0 0 0",
            }}
          >
            <div>
              <DetaTabla
                campos={dataCaso}
                setTab={setTabValue}
                preexistencias={preexistencias}
                rol={rol}
                deshabilitaInputs={deshabilitaInputs}
                correo={correo}
                sucursal={sucursal}
                refetch={refetch}
                cargandoDatos={loading}
              />
            </div>
            <Tabs
              sx={{ margin: '16px 0 16px 0' }}
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { background: '#00837A' } }}
            >
              <Tab
                label="Información del caso"
                {...a11yProps(0)}
              />
              <Tab
                label={dataCaso.tipoCaso === "GES" ? "Canastas y Prestadores" : "Prestadores"}
                {...a11yProps(1)}
              />
              <Tab
                label="Hitos"
                hidden={dataCaso.tipoCaso === "GES" ? 0 : 1}
                disabled={
                  dataCaso.fkEstadoSolicitudIsapre === 4 ||
                  dataCaso.fkEstadoSolicitudIsapre === 6 ||
                  dataCaso.fkEstadoSolicitudIsapre === 7 ||
                  dataCaso.fkEstadoSolicitudIsapre === 10 ||
                  dataCaso.fkEstadoSolicitudIsapre === 9 ? false : true}
                {...a11yProps(2)}
              />
              <Tab
                label="Bitácora"
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>
        </div>
      </div>

      <div>
        <TabPanel value={tabValue} index={0}>
          <InformacionDelCaso info={dataCaso} deshabilitaInputs={deshabilitaInputs || isContralor(rol) || dataCaso.fkEstadoSolicitudIsapre === 10} sucursal={sucursal} refetch={refetch} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {dataCaso.tipoCaso !== "CAEC" ? (
            <div id="canasta" data-testid="sinCAEC">
              <GES data={dataCaso} deshabilitaInputs={deshabilitaInputs || isContralor(rol) || dataCaso.fkEstadoSolicitudIsapre === 10} sucursal={sucursal} rol={rol} />
            </div>
          ) : (
            <CAEC data={dataCaso} deshabilitaInputs={deshabilitaInputs || isContralor(rol)} sucursal={sucursal} />
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <GesCaeHitos info={dataCaso} deshabilitaInputs={deshabilitaInputs || isContralor(rol) || dataCaso.fkEstadoSolicitudIsapre === 10} sucursal={sucursal} rol={rol} />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <GesCaeBitacora data={dataCaso} deshabilitaInputs={deshabilitaInputs} sucursal={sucursal} rol={rol}/>
        </TabPanel>
      </div>
    </>
  );
};

export default Main;
