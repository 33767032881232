export function soloNumeros(e) {
  var key = window.event ? e.which : e.keyCode;
  if (key < 48 || key > 57) {
    e.preventDefault();
  }
}

export function capitalize(s) {
  return s.toLowerCase().replace(/\b./g, function (a) {
    return a.toUpperCase();
  });
}

export function convertirEstados(valor) {
  switch (valor) {
    case 1:
      return "Ingresado";
    case 2:
      return "Revisión agencia";
    case 3:
      return "Revisión GEP";
    case 4:
      return "Autorizado";
    case 5:
      return "Rechazado";
    case 6:
      return "Anulado";
    case 7:
      return "Cerrado";
    case 8:
      return "Pre-ingresado";
    case 9:
      return "Re-Abierto";
    case 10:
      return "Cerrado por sistema";
    default: return ""
  }
}

export function defineEstado(estado) {
  switch (estado) {
    case 1:
      return <label className="tagEstadoCasos tagRevision">Ingresado</label>;
    case 2:
      return <label className="tagEstadoCasos tagRevision">Revisión agencia</label>;
    case 3:
      return <label className="tagEstadoCasos tagRevision">Revisión GEP</label>;
    case 4:
      return <label className="tagEstadoCasos tagIngresado">Autorizado</label>;
    case 5:
      return <label className="tagEstadoCasos tagRechazado">Rechazado</label>;
    case 6:
      return <label className="tagEstadoCasos tagRechazado">Anulado</label>;
    case 7:
      return <label className="tagEstadoCasos tagCerrado">Cerrado</label>;
    case 8:
      return <label className="tagEstadoCasos tagExceptuado">Pre-ingresado</label>;
    case 9:
      return <label className="tagEstadoCasos tagIngresado">Re-Abierto</label>;
    case 10:
        return <label className="tagEstadoCasos tagCerrado">Cerrado por sistema</label>;
    default:
      return "Opcion no Valida";
  }
}

export const EstadoHito = ({ estado }) => {
  switch (estado) {
    case 1:
      return <label className="tagEstadoCasos tagIngresado">Cierre en plazo</label>;
    case 2:
      return <label className="tagEstadoCasos tagRechazado">Cierre fuera de plazo</label>;
    case 3:
      return <label className="tagEstadoCasos tagRevision">Abierto fuera de plazo</label>;
    case 4:
      return <label className="tagEstadoCasos tagCerrado">Abierto en Proceso</label>;
    default:
      return <></>;
  }
}

export const ExepcionTag = ({ value }) => <label className="tagEstadoCasos tagExceptuado" style={{ marginTop: "4px" }}>Exceptuado{value ? `: ${value}` : "" }</label>;

export function defineOrigen(origen) {
  switch (origen) {
    case 1:
      return <label className="tagEstadoCasos tagCerrado">GC</label>;
    case 2:
      return <label className="tagEstadoCasos tagAutorizado">SV</label>;
    case 3:
      return <label className="tagEstadoCasos tagCerrado">Farmacia</label>;
    case 4:
      return <label className="tagEstadoCasos tagAutorizado">SF</label>;
    case 5:
      return <label className="tagEstadoCasos tagAutorizado">APP</label>;
    default:
      return "Opcion no Valida";
  }
}
