import {
  gql,
} from "@apollo/client";

export const guardaform = gql`
mutation ingresarformulario(
  $link: String!
  $tipoDoc: String!
  $folio: Float!
) {
  ingresarformulario(link: $link, tipoDoc: $tipoDoc, folio: $folio)
}
`
export const filtrado = gql`
query (
  $order:String!,
  $dir:String!
  $offset: Float, 
  $limit: Float
  $fkFun: Int
  $tipoCaso: String
  $rutPaciente: String
  $nombrePaciente: String
  $fkEstadoSolicitudIsapre: Int
  $fechaInicio: DateTime
  $fechaFin: DateTime
  $fkProblemaSalud: Int
  $origen: Int
  $numSolicitudOrigen: String
) {
  getSolicitudesGCbyparam(
    order:$order,
    dir:$dir,
    offset: $offset, 
    limit: $limit,
    fkFun: $fkFun
    tipoCaso: $tipoCaso
    rutPaciente: $rutPaciente
    nombrePaciente: $nombrePaciente
    fkEstadoSolicitudIsapre: $fkEstadoSolicitudIsapre
    fechaInicio: $fechaInicio
    fechaFin: $fechaFin
    fkProblemaSalud: $fkProblemaSalud
  	origen: $origen
  	numSolicitudOrigen: $numSolicitudOrigen
  ) {
    total
    solicitudes{
      fkFun    
    estadoHito{
      id
      descripcion
    }
    tipoCaso
    rutPaciente
    numSolicitudOrigen
    nombrePaciente
    apellido1Paciente
    apellido2Paciente
    fkProblemaSalud
    diagnosticoCaec
    nombrepat {
      GlosaSis
    }
    fechaRecepcionSolicitud
    fechaSolicitudIsapre
    fkEstadoSolicitudIsapre
    declaraHospitalizacion
    razonSocialInstitucionHopsitalizacion
    nombreRepresentante
    origen
    origenR
    apellido1Representante
    apellido2Representante
    createdUser
    arancel
    fechaCierre
    motivoCierre
    exeptuar
    }
  }
}
`;

export const registerSolicitud = gql`
mutation registerSolicitudSGC(
  $rutPaciente: String
  $nombrePaciente: String
  $apellido1Paciente: String
  $apellido2Paciente: String
  $codLocalidadPaciente: String!
  $celPaciente: Float
  $telPaciente: Float
  $dir2Paciente: String
  $dirPaciente: String!
  $emailPaciente: String!
  $rutRepresentante: String
  $fkTipoRepresentante: Int
  $fkTipoDocRepresentante: Int
  $nombreRepresentante: String
  $apellido1Representante: String
  $apellido2Representante: String
  $emailRepresentante: String!
  $telRepresentante: Int
  $celRepresentante: Int
  $fkProblemaSalud: Int
  $diagnosticoCaec: String
  $fechaRecepcionSolicitud: DateTime
  $fkTipoAntecedente: Int
  $antecedenteMedIdGD: Int
  $fkEventoAdministrativoDeclarado: Int
  $fechaEventoAdministrativo: DateTime
  $rutPrestadorInstitucional: String
  $razonSocialPrestador: String
  $rutMedicoTratante: String
  $nombreMedicoTratante: String
  $especialidadMedicoTratante: String
  $especialidadOtroMedicoTratante: Boolean
  $tratamiento: String
  $declaraHospitalizacion: Boolean
  $fechaHospitalizacionDesde: DateTime
  $fechaHospitalizacionHasta: DateTime
  $rutInstitucionHopsitalizacion: String
  $razonSocialInstitucionHopsitalizacion: String
  $declaraTraslado: Boolean
  $origenDestinoTraslado: String
  $destinoTraslado: String
  $fkEstadoSolicitudIsapre: Int
  $fechaSolicitudIsapre: DateTime
  $fechaAutorizacionSoliciut: DateTime
  $fkCausalRechazo: Int
  $fechaCierre: DateTime
  $fkCausalCierre: Int
  $beneficiarioNotificado: Boolean
  $fechaNotificacionBeneficiario: DateTime
  $fkTipoResolucionBeneficiario: Int
  $fechaResolucionBeneficiario: DateTime
  $declaraUrgenciaVital: Boolean
  $fVigenciaBeneficiario: DateTime 
  $sucursal: String
  $createdUser: String
  $updatedUser: String
  $tipoCaso: String
  $rutCotizante:String
  $sexoBeneficiario:String
  $nombreCotizante: String
  $plan: String
  $fechaNacBen:String
  $luatPrestadorInstitucional: String
  $direccionPrestadorInstitucional: String
  $luatHospitalizacion: String
  $direccionHospitalizacion: String
) {
  registerSolicitudSGC(
    rutCotizante:$rutCotizante,
    plan:$plan,
    nombreCotizante:$nombreCotizante,
    sexoBeneficiario:$sexoBeneficiario,
    fechaNacBen:$fechaNacBen,
    rutPaciente: $rutPaciente
    fVigenciaBeneficiario:$fVigenciaBeneficiario
    nombrePaciente: $nombrePaciente
    apellido1Paciente: $apellido1Paciente
    apellido2Paciente: $apellido2Paciente
    codLocalidadPaciente: $codLocalidadPaciente
    celPaciente: $celPaciente
    telPaciente: $telPaciente
    dirPaciente: $dirPaciente
    dir2Paciente: $dir2Paciente
    emailPaciente: $emailPaciente
    rutRepresentante: $rutRepresentante
    fkTipoRepresentante: $fkTipoRepresentante
    fkTipoDocRepresentante: $fkTipoDocRepresentante
    nombreRepresentante: $nombreRepresentante
    apellido1Representante: $apellido1Representante
    apellido2Representante: $apellido2Representante
    emailRepresentante: $emailRepresentante
    telRepresentante: $telRepresentante
    celRepresentante: $celRepresentante
    fkProblemaSalud: $fkProblemaSalud
    diagnosticoCaec: $diagnosticoCaec
    fechaRecepcionSolicitud: $fechaRecepcionSolicitud
    fkTipoAntecedente: $fkTipoAntecedente
    antecedenteMedIdGD: $antecedenteMedIdGD
    fkEventoAdministrativoDeclarado: $fkEventoAdministrativoDeclarado
    fechaEventoAdministrativo: $fechaEventoAdministrativo
    rutPrestadorInstitucional: $rutPrestadorInstitucional
    razonSocialPrestador: $razonSocialPrestador
    rutMedicoTratante: $rutMedicoTratante
    nombreMedicoTratante: $nombreMedicoTratante
    especialidadMedicoTratante: $especialidadMedicoTratante
    especialidadOtroMedicoTratante: $especialidadOtroMedicoTratante
    tratamiento: $tratamiento
    declaraHospitalizacion: $declaraHospitalizacion
    fechaHospitalizacionDesde: $fechaHospitalizacionDesde
    fechaHospitalizacionHasta: $fechaHospitalizacionHasta
    rutInstitucionHopsitalizacion: $rutInstitucionHopsitalizacion
    razonSocialInstitucionHopsitalizacion: $razonSocialInstitucionHopsitalizacion
    declaraTraslado: $declaraTraslado
    origenDestinoTraslado: $origenDestinoTraslado
    destinoTraslado: $destinoTraslado
    fkEstadoSolicitudIsapre: $fkEstadoSolicitudIsapre
    fechaSolicitudIsapre: $fechaSolicitudIsapre
    fechaAutorizacionSoliciut: $fechaAutorizacionSoliciut
    fkCausalRechazo: $fkCausalRechazo
    fechaCierre: $fechaCierre
    fkCausalCierre: $fkCausalCierre
    beneficiarioNotificado: $beneficiarioNotificado
    fechaNotificacionBeneficiario: $fechaNotificacionBeneficiario
    fkTipoResolucionBeneficiario: $fkTipoResolucionBeneficiario
    fechaResolucionBeneficiario: $fechaResolucionBeneficiario
    declaraUrgenciaVital: $declaraUrgenciaVital
    sucursal: $sucursal
    createdUser: $createdUser
    updatedUser: $updatedUser
    tipoCaso: $tipoCaso
    luatPrestadorInstitucional:$luatPrestadorInstitucional
    direccionPrestadorInstitucional: $direccionPrestadorInstitucional
    luatHospitalizacion: $luatHospitalizacion
    direccionHospitalizacion: $direccionHospitalizacion
  ) {
    id
    fkFun
    tipoCaso
    rutPaciente
    nombrePaciente
    apellido1Paciente
    apellido2Paciente
    codLocalidadPaciente
    celPaciente
    telPaciente
    dirPaciente
    dir2Paciente
    emailPaciente
    rutRepresentante
    fkTipoRepresentante
    fkTipoDocRepresentante
    nombreRepresentante
    apellido1Representante
    apellido2Representante
    emailRepresentante
    telRepresentante
    celRepresentante
    fkProblemaSalud
    diagnosticoCaec
    fechaRecepcionSolicitud
    fkTipoAntecedente
    antecedenteMedIdGD
    fkEventoAdministrativoDeclarado
    fechaEventoAdministrativo
    rutPrestadorInstitucional
    razonSocialPrestador
    rutMedicoTratante
    nombreMedicoTratante
    especialidadMedicoTratante
    especialidadOtroMedicoTratante
    tratamiento
    declaraHospitalizacion
    fechaHospitalizacionDesde
    fechaHospitalizacionHasta
    rutInstitucionHopsitalizacion
    razonSocialInstitucionHopsitalizacion
    declaraTraslado
    origenDestinoTraslado
    destinoTraslado
    fkEstadoSolicitudIsapre
    fechaSolicitudIsapre
    beneficiarioNotificado
    fechaNotificacionBeneficiario
    declaraUrgenciaVital
  }
}
`


export const SGCbyfolio = gql`
query($fkFun: Float!){
  getSolicitudesGCbyFkfun(fkFun:$fkFun){
    fkFun
    tipoCaso
    arancel
    rutPaciente
    nombrePaciente
    apellido1Paciente
    apellido2Paciente
    diagnosticoCaec
    fkProblemaSalud
    nombrepat{
      GlosaCorta 
      GlosaSis
      Resolutor 
      cie10{
        codigo
        Descripcion
    }}
    emailPaciente
    telPaciente
    celPaciente
    emailRepresentante
    telRepresentante
    celRepresentante
    fechaRecepcionSolicitud
    fechaAutorizacionSoliciut
    fkEstadoSolicitudIsapre
    declaraHospitalizacion
    razonSocialInstitucionHopsitalizacion
    nombreRepresentante
    rutRepresentante
    apellido1Representante
    apellido2Representante
    createdUser
    marca
    fechaCierre
    fechaInicioMarca
    comentarioMarca
    fkCie10
    causalCierre
    fVigenciaBeneficiario
  }
  }
`;

export const allcie10 = gql`
query{
  getCie10{
    codigo
    Descripcion
  }
}
`
export const getEstados = gql`
query{
  getSGCEstados{
    value
    label
  } 
}
`
export const getCiudades = gql`
      query GetCiudades($idReg: String!) {
        getCiudades(idReg: $idReg) {
          idCiu
          nomCiu
        }
      }
    `;
export const obtencomunas = gql`
    query GetComunas($idCiu: String!) {
      getComunas(idCiu: $idCiu) {
        idCom
        nomCom
      }
    }
  `;
export const editaFechaCanasta = gql`
  mutation(
    $fechaInicio: DateTime!
    $fechaTermino: DateTime
    $id: Float!
    $estadoCaso: Float
  	$sucursal: String
  	$usuario:String
    ){
    editaCanasta(fechaInicio:$fechaInicio,
      estadoCaso:$estadoCaso,
      fechaTermino:$fechaTermino,
      id:$id,
    	usuario:$usuario,
    	sucursal:$sucursal)
    }
    `;

export const editaFechaPrestadores = gql`
    mutation(
      $fechaInicio: DateTime!
      $fechaTermino: DateTime
      $id: Float!
      $luat: String
      $direccion: String
      $sucursal: String
  	  $usuario:String
    ){
      editaPrestador(
        fechaInicio:$fechaInicio,
        fechaTermino:$fechaTermino,
        id:$id,
        luat: $luat,
        direccion: $direccion,
        usuario:$usuario,
    	  sucursal:$sucursal
      )  
    }
  `;

export const asignacanastas = gql`
mutation(
  $folio: Int!
  $canasta: String!
  $descripcion: String!
  $ciclo: Float
  $sucursal:String
  $userCreated: String!
  $updatedUser: String!
  $fechaInicio: DateTime!
  $fechaFin: DateTime
){
asignaCanasta(
  folio:$folio,
  canasta:$canasta,
  descripcion:$descripcion,
  ciclo:$ciclo,
  sucursal:$sucursal,
  userCreated:$userCreated,
  updatedUser:$updatedUser,
  fechaInicio:$fechaInicio,
  fechaFin:$fechaFin,
){
  id
  idDespliegue
  idBeneficios
}
}


`
export const consultaPrestadoresCanasta = gql`
query($region: String!, $canasta: String!){
  getPDLRbycanasta(canasta:$canasta,region:$region){
    id
    rut
    nombre
    direccion
    region
    ciudad
    luat
    comuna
    secuencia
    habitacion
  }
  
}
`
export const eliminabitacora = gql`
mutation(
  $id: Float!
  $usuario:String
  $sucursal:String){
    eliminaBitacora(id:$id,usuario:$usuario,sucursal:$sucursal)
  }
`
export const cargarData = gql`
query($codigo: String!){
  getParametro(codigo:$codigo){
    PARA_CODIGO
    PARA_DESCRIP
    PARA_VALOR
  }
}
`
export const leerRegistros = gql`
query(
  $folio:Float!
  $clasificacion:String
  $offset:Float
  $limit:Float
  $order:String!
  $dir:String!
){
  getBitacora(clasificacion:$clasificacion,folio:$folio,order:$order,dir:$dir,limit:$limit,offset:$offset){
    total
    bitacoras{
      id
      idDespliegue
      folio_solicitud
      rutPrestador
      razonSocial
      comentario
      createdby
      createdAt
      fechaCompromiso
      tipoDoc
      documento
      clasificacion
      descripcionclasificacion
    }
  }
}
  `;


export const saveBitacora = gql`
mutation(
  $folio_solicitud: Int!
  $rutPrestador: String
  $razonSocial: String
  $comentario: String
  $createdby: String!
  $fechaCompromiso: String
  $tipoDoc: Float
  $documento: String
  $clasificacion: Float!
	$sucursal:String){
  saveBitacora(
  folio_solicitud:$folio_solicitud
  clasificacion:$clasificacion,
  rutPrestador:$rutPrestador,
  razonSocial:$razonSocial,
  comentario:$comentario,
  createdby:$createdby,
  fechaCompromiso:$fechaCompromiso,
  tipoDoc:$tipoDoc,
  documento:$documento,
  sucursal:$sucursal
  ){
  id
  clasificacion
  }
  }
`

export const consultaCanastaasignadas = gql`
query(
  $folio:Float!
  $offset:Float
  $limit:Float
  $order:String!
  $dir:String!
  $aplicaVigencia:Boolean 
){
  getCanastasasignadas(
    folio:$folio,offset:$offset,
    limit:$limit,order:$order,dir:$dir){
    total
    canastas{
          id
    idDespliegue
    canasta
    fechaInicio
    fechaFin
    ciclo
    descripcion
    userCreated
    vigencia
    estado
    prestadoresAsignados(aplicaVigencia:$aplicaVigencia){
      id
      nombrePrestador
      luat
      tipoPrestador
      prestador
      direccionPrestador
      fechaInicio
      fechaTermino
      vigencia
      transitorio
      createdby
      createdAt
    }
    updatedAt
    createdAt
    updatedUser
    idBeneficios
  
  }
    }
  }

`
export const consultaPrestadores = gql`
query{
getPDLR{
  rut
  nombre
  direccion
  secuencia
  comuna
  region
  ciudad
}
}
`

export const activaPrestadores = gql`
query(
  $idCasoCanasta: BigInt!,
  $prestadores:[activaPrestador!],
 $numeroCaso: Float!
  $usuario: String!
  $sucursal: String!){
  activaPrestador(idCasoCanasta:$idCasoCanasta,prestadores:$prestadores,
    numeroCaso:$numeroCaso,usuario:$usuario,sucursal:$sucursal){
    codRespuesta
    tipoCreacion
    tipoActualizacion
    mensajeError
  }
}
`

export const activacaso = gql`
query(
  $codigoPatologia: Float!
  $fechaAperturaCaso: String!
  $numeroCaso: Float!
  $usuario: String!
  $sucursal: String!
  $rutBeneficiario: String!)
  {
  activacaso(
  rutBeneficiario: $rutBeneficiario,
  numeroCaso: $numeroCaso,
  fechaAperturaCaso: $fechaAperturaCaso, 
  codigoPatologia: $codigoPatologia 
  usuario:$usuario
  sucursal:$sucursal
  ){
  codRespuesta 
  tipoCreacion 
  mensajeError 
  }
  }
`
export const activacasoCaec = gql`
query(
  $numeroCaso: Float!
  $usuario: String!
  $sucursal: String!
  $rutBeneficiario: String!)
  {
  activacasoCaec(
  rutBeneficiario: $rutBeneficiario,
  numeroCaso: $numeroCaso,
  usuario:$usuario
  sucursal:$sucursal
  ){
  codRespuesta 
  tipoCreacion 
  mensajeError 
  }
  }
`

export const consultabeneficiarios = gql`
query($rut: String!,$fecha: String){
getBeneficiariosbyRut(rut:$rut,fecha:$fecha){
  rut
  relacion
  tipoBeneficiario
  inicioVigencia
  genero
  primerNombre
  segundoNombre
  primerApellido
  segundoApellido
  fechaNacimiento
  plan
  edad
  illnessAssessment{
    typeIll
    startDateTimeIas
    endDateTimeIas
    durationValueIas
  }
}
}
`
export const consultaHitoInicio = gql`query($problemaSalud:Float!){
  getGopInicio(problemaSalud:$problemaSalud){
  id
  problemaSalud
  nombreHito{
  descripcion
  }
  cod_prestacion
  grupo_prestacion
  intervencion
  eventoAdministrativo
    etapa
    plazo
    unidad
    
  
  }
  }
`
export const AsignarHito = gql`
mutation(
  $folio: Float!,
  $idHito: Float!
  $idHitoInicio: Float
  $fechaEvento: DateTime!
  $horaEvento: String!
  $prestador: String
  $razonSocial: String
  $canasta: String
  $region: String
  $creador: String!
  $ciudad: String
  $comuna: String
  $motivo: Float
  $exceptuar: Boolean
  $observacion: String
  $luatPrestador: String
  $direccionPrestador: String){
  asignaHito(
  folio:$folio,
  canasta:$canasta
  idHitoInicio:$idHitoInicio
  idHito:$idHito,
  prestador:$prestador,
  razonSocial:$razonSocial
  region:$region,
  creador:$creador,
  ciudad:$ciudad,
  comuna:$comuna,
  fechaEvento:$fechaEvento,
  horaEvento:$horaEvento,
  motivo:$motivo,
  exceptuar:$exceptuar,
  observacion:$observacion,
  luatPrestador: $luatPrestador
  direccionPrestador: $direccionPrestador){
  id
  }
  }
  `
export const motivosexcepcion = gql`
  query {
    getMotivosExcepcion {
      id
      descripcion
    }
  }
`;

export const clasificaciones = gql`
query{
  getClasificaciones{
    id
    descripcion
  }
}
`;

export const queryPDLR = gql`
      query ($rut: String!) {
        getPDLRbyrut(rut: $rut) {
          rut
          nombre
          region
          ciudad
          comuna
        }
      }
    `;

export const rechazo = gql`
mutation (
  $folio: Float!
	$usuario:String
  $fkCausalRechazo: Float!
	$sucursal:String
  $correo: String
	$comentarioRechazo: String) {
  rechazarFormulario(folio: $folio,
    fkCausalRechazo:$fkCausalRechazo,sucursal:$sucursal,correo:$correo,usuario:$usuario,comentarioRechazo:$comentarioRechazo)
}
`
export const motivosRechazo = gql`
query{
  getmotivosRechazo{
    id
    descripcion
  }
}
`;

export const motivosGeneral = gql`
query(
  $tipocaso:Float!
  $accion:Float!
){
  getmotivos(tipocaso:$tipocaso,accion:$accion){
    id
    descripcion
  }
}
`;



export const anularCaso = gql`
mutation(
  $sucursal: String
  $correo: String
  $usuario: String
  $comentarioAnulacion: String
  $folio: Float!
  $isInvalid: Boolean!
){
  anularCaso(folio:$folio,usuario:$usuario,correo:$correo,sucursal:$sucursal,comentarioAnulacion:$comentarioAnulacion,isInvalid: $isInvalid)
}
`;
/* MOTIVO CIERRE FRANCO */
export const motivoCierre = gql`
  query($tipo: String!){
      getmotivoscierre(tipo:$tipo){
      id
      descripcion
    }
  }
  `
/* CIERRE FRANCO  */
export const fechaCierreCausal = gql`  
  mutation(
  	$comentario: String
    $fkCausalCierre: Float!
    $folio: Float!
		$usuario:String
    $correo:String
    $pacienteVigente: Boolean!
		$sucursal: String){
    cerrarCaso(folio:$folio,fkCausalCierre:$fkCausalCierre,comentario:$comentario,usuario:$usuario,correo:$correo,sucursal:$sucursal,pacienteVigente:$pacienteVigente)
    }
    `;

export const reabrirCaso = gql`  
    mutation(
      $comentarioReapertura: String
      $fkCausalReapertura: Float!
      $folio: Float!
      $usuario:String
      $correo:String
      $sucursal: String){
      reabrirCaso(folio:$folio,fkCausalReapertura:$fkCausalReapertura,comentarioReapertura:$comentarioReapertura,usuario:$usuario,correo:$correo,sucursal:$sucursal)
      }
      `;



export const cie10 = gql`
query {
  getCie10 {
    codigo
    Descripcion
  }
}
`

export const getDescripcion = gql`
query($codigo:String!){
  getCie10byCodigo(codigo:$codigo){
  Descripcion
  }
  }
  `;

export const getsolicitudxfk = gql`
query ($fkFun: Float!) {
  getSolicitudesGCbyFkfun(fkFun: $fkFun) {
    fkFun
    tipoCaso
    arancel
    rutPaciente
    nombrePaciente
    apellido1Paciente
    apellido2Paciente
    dirPaciente
    dir2Paciente
    codLocalidadPaciente
    nombreRepresentante
    rutRepresentante
    apellido1Representante
    apellido2Representante
    diagnosticoCaec
    fkProblemaSalud
    vistoBueno
    nombrepat {
      GlosaCorta
      GlosaSis
      Resolutor
      cie10 {
        codigo
        Descripcion
      }
    }
    emailPaciente
    telPaciente
    celPaciente
    emailRepresentante
    telRepresentante
    celRepresentante
    fechaRecepcionSolicitud
    fechaAutorizacionSoliciut
    fkEstadoSolicitudIsapre
    declaraHospitalizacion
    razonSocialInstitucionHopsitalizacion
    createdUser
    marca
    fechaCierre
    fechaInicioMarca
    comentarioMarca
    fkCie10
    causalCierre
    descCausal
    tratamiento
    fVigenciaBeneficiario
    rutCotizante
    plan
    nombreCotizante
    numSolicitudOrigen
    origen
    estadoHito{
      id
      descripcion
    }
    rutPrestadorInstitucional
    razonSocialPrestador
    direccionPrestadorInstitucional
    rutMedicoTratante
    nombreMedicoTratante
    especialidadMedicoTratante
    especialidadOtroMedicoTratante
    tratamiento
    declaraHospitalizacion
    fechaHospitalizacionDesde
    fechaHospitalizacionHasta
    rutInstitucionHopsitalizacion
    razonSocialInstitucionHopsitalizacion
    direccionHospitalizacion
    declaraTraslado
    origenDestinoTraslado
    destinoTraslado
    declaraUrgenciaVital
    deducible
    pacienteVigente
  }
}
`
export const getsolicitudsv = gql`
query ($fkFun: Float!) {
  getSolicitudesGCbyFkfun(fkFun: $fkFun) {
    fkFun
    tipoCaso
    rutPaciente
    nombrePaciente
    apellido1Paciente
    apellido2Paciente
    codLocalidadPaciente
    diagnosticoCaec
    fkProblemaSalud
    emailPaciente
    telPaciente
    celPaciente
    dirPaciente
    dir2Paciente
    rutRepresentante
    nombreRepresentante
    apellido1Representante
    apellido2Representante
    emailRepresentante
    telRepresentante
    celRepresentante
    diagnosticoCaec
    fkTipoAntecedente
    tratamiento
    declaraTraslado
    declaraUrgenciaVital
    fechaRecepcionSolicitud
    fechaSolicitudIsapre
    fkEstadoSolicitudIsapre
    declaraHospitalizacion
    razonSocialInstitucionHopsitalizacion
    direccionHospitalizacion
    rutInstitucionHopsitalizacion
    luatHospitalizacion
    createdUser
    tratamiento
    arancel
    origen
    fechaHospitalizacionDesde
    fechaHospitalizacionHasta
    destinoTraslado
    origenDestinoTraslado
  }
}
`

export const obtenhitosasignados = gql`
  query($folio: Float!, $offset: Float!, $limit: Float!){
    gethitosAsignados(folio:$folio, offset: $offset, limit: $limit){
        total
        hitos{
          hitoInicio{
          id
          idDespliegue
          creador
          fechaEvento
          horaEvento
          descripcionEstado
          idHitotermino
          datosHito{
          id
          nombreHito{descripcion}
          plazo
          unidad
          etapa
          }
          }
          hitoTermino{
          id
          idDespliegue
          fechaEvento
          horaEvento
          exceptuar
          datosHito{
              id
              nombreHito{descripcion}
              plazo
              unidad
              etapa
          }
          }
        }
    }
  }
`;

export const consultaHitoTermino = gql`query(
  $etapa: Float!, $problemaSalud: Float!){
getGopTermino(etapa:$etapa,problemaSalud:$problemaSalud){
id
etapa
nombreHito{descripcion}
problemaSalud
intervencion
grupo_prestacion
cod_prestacion
}


}
  `
export const resumenHitos = gql`query($idHitoAsignado: Float!){
    consultaHitoAsignado(idHitoAsignado:$idHitoAsignado){
        hitoInicio{
            id
            idDespliegue
            datosHito{
                nombreHito{descripcion}
                problemaSalud
                intervencion
                grupo_prestacion
                cod_prestacion
            }
            fechaEvento
            horaEvento
            prestador
            razonSocial
            creador
            region
            ciudad
            comuna
            motivo
            observacion
        }
        hitoTermino{
            id
            datosHito{
                nombreHito{descripcion}
                problemaSalud
                intervencion
                grupo_prestacion
                cod_prestacion
            }
            fechaEvento
            horaEvento
            prestador
            razonSocial
            region
            ciudad
            comuna
            motivo
            observacion
            creador
        }
    }
}
`

export const getUltRep = gql`
query{
  getUltREporte{
    id
    periodo
    descripcion
    fechaGeneracion
    creador
    urlXls
    urlTxt
    
  }
}
`

export const buscaplan = gql`query($rut: String!,$fecha: String){
getBeneficiariosbyRut(rut:$rut,fecha:$fecha){
  rut
  plan
  relacion
  inicioVigencia
}
}`

export const asignaprestador = gql`
mutation(
  $folio: Int!
  $idcanastaAsignada: Float
  $canasta: String
  $luat: String
  $prestador: String!
  $nombrePrestador: String!
  $direccionPrestador: String!
  $motivoFueraRed: Float
  $fechaInicio: DateTime!
  $fechaTermino: DateTime
  $tipoPrestador: String!
  $comentario: String
  $transitorio: Boolean
  $createdby: String!
  $tipoCaso: String!
  $sucursal:String){
asignaPrestador(
  folio:$folio,
  idcanastaAsignada:$idcanastaAsignada,
  canasta:$canasta,
  luat:$luat,
  sucursal:$sucursal
  prestador:$prestador,
  nombrePrestador:$nombrePrestador,
  direccionPrestador:$direccionPrestador,
  motivoFueraRed:$motivoFueraRed,
  fechaInicio:$fechaInicio,
  fechaTermino:$fechaTermino,
  tipoPrestador:$tipoPrestador,
  comentario:$comentario,
  transitorio:$transitorio,
  createdby:$createdby,
  tipoCaso:$tipoCaso,
){
  id
  idDespliegue
}
}
`

export const getRegiones = gql`
query GetRegiones {
  getRegiones {
    idReg
    nomReg
  }
}
`;
export const docAsociados = gql`query($folio:Float!){
  getDocumentosasociados(folio:$folio){
  form1
  form2
  form1_firmado
  form2_firmado
  }
  }`

export const intsan = gql`
query ($patologia: Float!,$decreto:Float){
  getIntSanbyPat(patologia: $patologia,decreto:$decreto) {
    codigo
    nombre
  }
}
`;

export const buscaprestador = gql`
      query get($rut: String!, $tipo: Float!) {
        getPrestadorbyRut(rut: $rut, tipo: $tipo) {
          rut
          RazonSocial
          direccion
          region
          ciudad
          comuna
        }
      }
    `;
export const motivoFueraRed = gql`
  query{
    getMotivosFueraRed{
      id
      descripcion
    }
  }
  `;

export const querymedico = gql`
  query get($rut: String!, $tipo: Float!) {
    getPrestadorbyRut(rut: $rut, tipo: $tipo) {
      rut
      NomMed
      ApeMed1
      ApeMed2
      Especialidades {
        idEsp
        descEsp
      }
    }
  }
`;

export const getps = gql`
query GetProblemasSalud {
  getProblemasSalud {
    Codigo
    GlosaCorta
    GlosaSis
  }
}
`
export const gettiposdocumento = gql`
query{
  getCatalogoDoc{
    IdProceso
    IdTipoDocumento
    IdTipoTransaccion
    TipoDocumento
  }
}
`
export const getbeneficiarioxrut = gql`
query ($rut: String!,$fecha: String) {
  getBeneficiariosbyRut(rut: $rut,fecha:$fecha) {
    rut
    relacion
    tipoBeneficiario
    inicioVigencia
    genero
    primerNombre
    segundoNombre
    primerApellido
    segundoApellido
    fechaNacimiento
    plan
    edad
  }
}
`

export const prestadoresAsignados = gql`
query(
  $folio:Float!
  $offset:Float
  $limit:Float
  $order:String!
  $dir:String!
){
  getPrestadoresAsignados(
    folio:$folio,offset:$offset,
    limit:$limit,order:$order,dir:$dir){
    total
    prestadores{
      id
      idDespliegue
      idcanastaAsignada
      prestador
      nombrePrestador
      direccionPrestador
      canasta
      dataCanasta{
        descripcion
      }
      fechaInicio
      fechaTermino
      luat
      transitorio
      tipoPrestador
      createdby
      createdAt
      vigencia
    }
  }
}
`;


export const guardaPrestador = gql`
mutation(
  $folio: Int!
  $idcanastaAsignada: Float
  $canasta: String
  $prestador: String!
  $nombrePrestador: String!
  $direccionPrestador: String!
  $motivoFueraRed: Float
  $fechaInicio: DateTime!
  $fechaTermino: DateTime
  $tipoPrestador: String!
  $comentario: String
  $transitorio: Boolean
  $createdby: String!
  $luat: String
  $sucursal: String
  $tipoCaso: String!){
  asignaPrestador(
  folio:$folio,
  luat:$luat,
  idcanastaAsignada:$idcanastaAsignada,
  canasta:$canasta,
  prestador:$prestador,
  sucursal:$sucursal,
  nombrePrestador:$nombrePrestador,
  direccionPrestador:$direccionPrestador,
  motivoFueraRed:$motivoFueraRed,
  fechaInicio:$fechaInicio,
  fechaTermino:$fechaTermino,
  tipoPrestador:$tipoPrestador,
  comentario:$comentario,
  transitorio:$transitorio,
  createdby:$createdby,
  tipoCaso:$tipoCaso,
  ){
  id
  }
  }
`;


export const eliminaPrestador = gql`
          mutation ($id: Float!) {
            eliminarPrestador(id: $id) {
              prestador
            }
          }
        `

export const cuentaprestadores = gql`
query(
  $folio:Float!
  $filtraSB:Boolean){
  cuentaprestadores(folio:$folio,filtraSB:$filtraSB){
      contDR
      conFR
  }
}
  `;

export const subereporteTxt = gql`
  query(
    $nombreTxtArchivo: String
    $base64Txt: String
    $tipoDoc: String
    $periodo: String){
  subeReporteTxt(
    nombreTxtArchivo: $nombreTxtArchivo,
    base64Txt: $base64Txt
    tipoDoc:$tipoDoc
    periodo: $periodo 
  )
}
  `;

export const subereporteXlsx = gql`
  query(
    $nombreTxtArchivo: String
    $idTxtArchivo: String
    $nombreXlsxArchivo: String
    $base64Xlsx: String
    $tipoDoc: String
    $periodo: String
    $creador: String){
  subeReportesXlsx(
    nombreTxtArchivo: $nombreTxtArchivo,
    idTxtArchivo: $idTxtArchivo
    nombreXlsxArchivo: $nombreXlsxArchivo
    base64Xlsx: $base64Xlsx
    tipoDoc:$tipoDoc
    periodo: $periodo
    creador: $creador  
  )
}
  `;

export const opcionesPrestador = gql`
    query{
      getPDLR{
      rut
      luat
      nombre
      direccion
      secuencia
      region
      ciudad
      comuna
      habitacion
      }
    }
    `;

export const eliminaCanasta = gql`
  mutation (
    $idCanastaAsignada: Float!
    $usuario:String!
    $sucursal:String!
    $estado:Float!
    ) {
    eliminaCanasta(
      idCanastaAsignada: $idCanastaAsignada,
      usuario:$usuario,
      sucursal:$sucursal,
      estado:$estado
      )
  }
`
export const canastasxPatologia = gql`
query ($patologia: Float!, $intervencion: String, $codigo: String, $descripcion: String, $arancel: Float) {
  getCanastasbyPat(patologia: $patologia, intervencion: $intervencion, codigo: $codigo, descripcion: $descripcion, arancel: $arancel) {
    intSan
    codigo
    descripcion
    vigenciaDesde
    vigenciaHasta
  }
}
`;
export const cuentaprestxfolio = gql`
query ($folio: Float!) {
  cuentaprestadoresxfolio(folio: $folio)
}
`
export const cuentaprestxcan = gql`
query($folio: Float!, $canastaAsignada: Float){
  cuentaprestadoresxcanasta(folio:$folio, canastaAsignada:$canastaAsignada){
  conFR
  contDR
  }
  }
  `;
export const updtsolicitud = gql`
mutation (
  $fkFun: Int
  $celPaciente: Float
  $telPaciente: Float
  $emailPaciente: String!
  $emailRepresentante: String!
  $telRepresentante: Int
  $celRepresentante: Int
  $fechaCierre: DateTime
  $causalCierre: String
  $fkCie10: String
  $marca: String
  $fechaInicioMarca: DateTime
  $comentarioMarca: String
  $fechaAutorizacionSoliciut: DateTime
  $usuario: String
  $sucursal: String
  $cambiafecha: Boolean
) {
  updateSolicitud(
    fkFun: $fkFun
    celPaciente: $celPaciente
    telPaciente: $telPaciente
    emailPaciente: $emailPaciente
    emailRepresentante: $emailRepresentante
    telRepresentante: $telRepresentante
    celRepresentante: $celRepresentante
    fechaCierre: $fechaCierre
    causalCierre: $causalCierre
    fkCie10: $fkCie10
    marca: $marca
    fechaInicioMarca: $fechaInicioMarca
    comentarioMarca: $comentarioMarca
    fechaAutorizacionSoliciut: $fechaAutorizacionSoliciut
    usuario : $usuario
    sucursal: $sucursal
    cambiafecha: $cambiafecha
  ) {
    fkFun
  }
}
`
export const autoriza = gql`
mutation (
  $folio: Float!
	$usuario:String
  $correo:String
	$sucursal:String
  $deducible:Float
  ) {
  autorizarFormulario(folio: $folio,sucursal:$sucursal,correo:$correo,usuario:$usuario,deducible:$deducible)
}
`
export const buscaprestadorFR = gql`
query($rut: String){
  getPrestadoresbyRut(rut:$rut){
    internalRolId
    rut
    razonSocial
    nombreCalle
    numeroCalle
    region
    ciudad
    comuna
    vigenciaFinal
    vigenciaInicial
    luat
  } 
  }
`

export const buscaprestadorxRazon = gql`
query($razon:String!){
  getPrestadoresbyRazon(razon:$razon){
    rut
    razonSocial
    nombreCalle
    numeroCalle
    region
    ciudad
    comuna
    vigenciaFinal
    vigenciaInicial
    luat
  }
}
`
export const calculadeducible = gql`
query($tipoCaso: String!, $rut: String!,$casosPrevios: Boolean!){
  obtenerDeducible(tipoCaso: $tipoCaso, rut: $rut,casosPrevios:$casosPrevios){
    rut
    tipoCaso
    deducible
    error
    status
  }
  }
`

export const cargararchivo = gql`
query(
  $idProceso:String!,
  $idTipoTrans:String!,
  $idTipoDoc:String!,
  $folioTrans:String!,
  $fechaTrans:String!,
  $rutBenef:String!,
  $folioDoc:String!,
  $nombreDoc:String!,
  $base64:String!,
  ){crearArchivo(
    idProceso:$idProceso
    idTipoTrans:$idTipoTrans
    idTipoDoc:$idTipoDoc,
    folioTrans:$folioTrans
    fechaTrans:$fechaTrans
    rutBenef:$rutBenef
    folioDoc:$folioDoc
    nombreDoc:$nombreDoc
    base64:$base64
    ){
    code
    documentId
    url
    error
  }
  }
`
export const cargararchivo2 = gql`
mutation(
  $idProceso:String!,
  $idTipoTrans:String!,
  $idTipoDoc:String!,
  $folioTrans:String!,
  $fechaTrans:String!,
  $rutBenef:String!,
  $folioDoc:String!,
  $nombreDoc:String!,
  $base64:String!
  ){crearArchivo2(
    idProceso:$idProceso
    idTipoTrans:$idTipoTrans
    idTipoDoc:$idTipoDoc,
    folioTrans:$folioTrans
    fechaTrans:$fechaTrans
    rutBenef:$rutBenef
    folioDoc:$folioDoc
    nombreDoc:$nombreDoc
    base64:$base64
    ){
    code
    documentId
    url
    error
  }
  }
`
export const obtenerarchivo = gql`
query($documentId:String!,$tipoDoc:String){
  obtenerArchivo(documentId:$documentId,tipoDoc:$tipoDoc){
    code
    nombExtArchivoSPO
    urlSPO
    base64
    error
  }
  }
`

export const getGop = gql`
query($patologia:Int,$estado:Int,$fechaInicio:String,$fechaTermino:String,$exceptuar: String,$estadoHito: Int){
  getReporteGOP(patologia:$patologia,estado:$estado,fechaInicio:$fechaInicio,fechaTermino:$fechaTermino,exceptuar:$exceptuar,estadoHito:$estadoHito){
    respuesta, error, data
  }
  }
`

export const verificarcaso = gql`
query($rut: String!){
  verCasosActivos(rut:$rut)
}
`

export const listaPrestadoresPorCanasta = gql`
query($idcanastaAsignada:Float!,$conVigencia:Boolean){
  getPrestadoresAsignadosxcanasta(idcanastaAsignada:$idcanastaAsignada,conVigencia:$conVigencia){
    fechaInicio
    fechaTermino
    prestador
    luat
    id
    tipoPrestador
  }

}
`
/*  Query Celula beneficio*/
export const celulaBeneficio = gql` 
query(
  $codigoPatologia: Float!
  $fechaAperturaCaso: String!
  $numeroCaso: Float!
  $rutBeneficiario: String!)
 {
   consultaBeneficiario(
     rutBeneficiario: $rutBeneficiario,
     numeroCaso: $numeroCaso,
     fechaAperturaCaso: $fechaAperturaCaso,
     codigoPatologia: $codigoPatologia
 ){
     codRespuesta
     tipoCreacion
     mensajeError
 }
}
`
export const activaCanasta = gql`
query( 
  $canasta: String!
    $numeroCaso: Float!
    $idCasoCanasta: BigInt!
    $fAperturaCanasta: String!
    $prestadores: [activaPrestador!],
		$usuario: String!
    $fechaTermino:String
  	$sucursal: String!){
  activaCanastabyCaso(
    usuario:$usuario,
    sucursal:$sucursal,
    fAperturaCanasta:$fAperturaCanasta
    numeroCaso:$numeroCaso,
    fechaTermino:$fechaTermino,
    idCasoCanasta:$idCasoCanasta,
    canasta:$canasta,
    prestadores:$prestadores,
    ){
    codRespuesta
    tipoCreacion
    mensajeError
  }
}
  `

export const generarFormulario1 = gql`
query($id:String!,
  $nombreBeneficiario:String!,
  $rutBeneficiario:String!,
  $nombrePaciente:String!,
  $rutPaciente:String!,
  $dirPaciente:String!,
  $ciudadPaciente:String!,
  $telPaciente:String!,
  $telOfiPaciente:String!,
  $celPaciente:String!,
  $diagnosticoGes:String,
  $diagnosticoCaec:String,
  $tratamiento:String!,
  $medicoTratante:String!,
  $especialidadMedTrat:String!,
  $razonSocialPrestador:String!,
  $hospSi:String!,
  $hospNo:String!,
  $fechaDesdeHosp:String!,
  $fechaHastaHosp:String!,
  $razonSocialHosp:String,
  $trasSi:String!,
  $trasNo:String!,
  $origenTraslado:String,
  $destinoTraslado:String,
  $nombreRepresentante:String!,
  $rutRepresentante:String!,
  $tipoRepresentante:String!,
  $telRepresentante:String!,
  $telOfiRepresentante:String!,
  $celRepresentante:String!,
  $sucursal:String!,
  $createUser:String!,
  $fechaRecepSolicitud:String!,
  $horaRecepSolicitud:String!
  ){
  generarFormulario1(id:$id,
    nombreBeneficiario:$nombreBeneficiario,
    rutBeneficiario:$rutBeneficiario,
    nombrePaciente:$nombrePaciente,
    rutPaciente:$rutPaciente,
    dirPaciente:$dirPaciente,
    ciudadPaciente:$ciudadPaciente,
    telPaciente:$telPaciente,
    telOfiPaciente:$telOfiPaciente,
    celPaciente:$celPaciente,
    diagnosticoGes:$diagnosticoGes,
    diagnosticoCaec:$diagnosticoCaec,
    tratamiento:$tratamiento,
    medicoTratante:$medicoTratante,
    especialidadMedTrat:$especialidadMedTrat,
    razonSocialPrestador:$razonSocialPrestador,
    hospSi:$hospSi,
    hospNo:$hospNo,
    fechaDesdeHosp:$fechaDesdeHosp,
    fechaHastaHosp:$fechaHastaHosp,
    razonSocialHosp:$razonSocialHosp,
    trasSi:$trasSi,
    trasNo:$trasNo,
    origenTraslado:$origenTraslado,
    destinoTraslado:$destinoTraslado,
    nombreRepresentante:$nombreRepresentante,
    rutRepresentante:$rutRepresentante,
    tipoRepresentante:$tipoRepresentante,
    telRepresentante:$telRepresentante,
    telOfiRepresentante:$telOfiRepresentante,
    celRepresentante:$celRepresentante,
    sucursal:$sucursal,
    createUser:$createUser,
    fechaRecepSolicitud:$fechaRecepSolicitud,
    horaRecepSolicitud:$horaRecepSolicitud    
    ){
    base64
    error
  }
  }
`

export const generarFormulario2 = gql`
query($p1Establecimiento:String!,
  $p1Direccion:String!,
  $p1Habitacion:String!,
  $p1Medico:String!,
  $p2Establecimiento:String,
  $p2Direccion:String,
  $p2Habitacion:String!,
  $p2Medico:String!,
  $nombreBeneficiario:String!,
  $rutBeneficiario:String!,
  $nombrePaciente:String!,
  $rutPaciente:String!,
  $coberturaGES:String!,
  $coberturaCAEC:String!,
  $cie10:String!,
  $diagnostico:String!,
  $tratamiento:String!,
  $fechaSolicitud:String!,
  $vb:String!,
  $fechaIniBenef:String!,
  $montoDeducible:String!,
  $fecha:String!,
  $hora:String!,
  $informe:String!,
  $rut:String!,
  $dv:String!
  ){
  generarFormulario2(p1Establecimiento:$p1Establecimiento,
    p1Direccion:$p1Direccion,
    p1Habitacion:$p1Habitacion,
    p1Medico:$p1Medico,
    p2Establecimiento:$p2Establecimiento,
    p2Direccion:$p2Direccion,
    p2Habitacion:$p2Habitacion,
    p2Medico:$p2Medico,
    nombreBeneficiario:$nombreBeneficiario,
    rutBeneficiario:$rutBeneficiario,
    nombrePaciente:$nombrePaciente,
    rutPaciente:$rutPaciente,
    coberturaGES:$coberturaGES,
    coberturaCAEC:$coberturaCAEC,
    cie10:$cie10,
    diagnostico:$diagnostico,
    tratamiento:$tratamiento,
    fechaSolicitud:$fechaSolicitud,
    vb:$vb,
    fechaIniBenef:$fechaIniBenef,
    montoDeducible:$montoDeducible,
    fecha:$fecha,
    hora:$hora,
    informe:$informe,
    rut:$rut,
    dv:$dv        
    ){
    base64
    error
  }
  }
`

export const generarReporte = gql`
query(
  $anio: String!, 
  $mm: String!, 
  $idReporte: String!, 
  $offSet: Float!,
  $limit: Float!,
  ){
  generarReporte(
    anio:$anio,
    mm:$mm,
    idReporte:$idReporte,
    offSet: $offSet,
    limit: $limit)
    {
      nombreTxtArchivo,
      nombreXlsxArchivo,
      tipoDoc,
      codAseguradora,
      info,
      error,
      count
    } 
  }`

export const verificarcasoexistente = gql`
  query(
    $problemaSalud: Float!
    $rut: String!
    $folio: Float
  ){
    verificarCasoExistente(problemaSalud:$problemaSalud,rut:$rut, folio: $folio)
  }`

export const actualizaCaso = gql`
mutation guardaPreingresados(
  $folio:Float!
  $codLocalidadPaciente: String!
  $nombrePaciente: String
  $apellido1Paciente: String
  $apellido2Paciente: String
  $celPaciente: Float
  $telPaciente: Float
  $dir2Paciente: String
  $dirPaciente: String!
  $emailPaciente: String!
  $rutRepresentante: String
  $fkTipoRepresentante: Int
  $fkTipoDocRepresentante: Int
  $nombreRepresentante: String
  $apellido1Representante: String
  $apellido2Representante: String
  $emailRepresentante: String!
  $telRepresentante: Int
  $celRepresentante: Int
  $fkProblemaSalud: Int
  $diagnosticoCaec: String
  $fechaRecepcionSolicitud: DateTime
  $fkTipoAntecedente: Int
  $antecedenteMedIdGD: Int
  $fkEventoAdministrativoDeclarado: Int
  $fechaEventoAdministrativo: DateTime
  $rutPrestadorInstitucional: String
  $razonSocialPrestador: String
  $rutMedicoTratante: String
  $nombreMedicoTratante: String
  $especialidadMedicoTratante: String
  $especialidadOtroMedicoTratante: Boolean
  $tratamiento: String
  $declaraHospitalizacion: Boolean
  $fechaHospitalizacionDesde: DateTime
  $fechaHospitalizacionHasta: DateTime
  $rutInstitucionHopsitalizacion: String
  $razonSocialInstitucionHopsitalizacion: String
  $declaraTraslado: Boolean
  $origenDestinoTraslado: String
  $destinoTraslado: String
  $fkEstadoSolicitudIsapre: Int
  $fechaSolicitudIsapre: DateTime
  $fechaAutorizacionSoliciut: DateTime
  $fkCausalRechazo: Int
  $fechaCierre: DateTime
  $fkCausalCierre: Int
  $beneficiarioNotificado: Boolean
  $fechaNotificacionBeneficiario: DateTime
  $fkTipoResolucionBeneficiario: Int
  $fechaResolucionBeneficiario: DateTime
  $declaraUrgenciaVital: Boolean
  $fVigenciaBeneficiario: DateTime 
  $sucursal: String
  $createdUser: String
  $updatedUser: String
  $tipoCaso: String
  $rutCotizante:String
  $sexoBeneficiario:String
  $nombreCotizante: String
  $plan: String
  $fechaNacBen:String
  $luatPrestadorInstitucional: String
  $direccionPrestadorInstitucional: String
  $luatHospitalizacion: String
  $direccionHospitalizacion: String
  $userEmail: String
) {
  guardaPreingresados(
    folio:$folio
    rutCotizante:$rutCotizante,
    plan:$plan,
    nombreCotizante:$nombreCotizante,
    sexoBeneficiario:$sexoBeneficiario,
    fechaNacBen:$fechaNacBen,
    fVigenciaBeneficiario:$fVigenciaBeneficiario
    codLocalidadPaciente: $codLocalidadPaciente
    celPaciente: $celPaciente
    telPaciente: $telPaciente
    dirPaciente: $dirPaciente
    dir2Paciente: $dir2Paciente
    emailPaciente: $emailPaciente
    rutRepresentante: $rutRepresentante
    fkTipoRepresentante: $fkTipoRepresentante
    fkTipoDocRepresentante: $fkTipoDocRepresentante
    nombreRepresentante: $nombreRepresentante
    apellido1Representante: $apellido1Representante
    apellido2Representante: $apellido2Representante
    emailRepresentante: $emailRepresentante
    telRepresentante: $telRepresentante
    celRepresentante: $celRepresentante
    fkProblemaSalud: $fkProblemaSalud
    diagnosticoCaec: $diagnosticoCaec
    fechaRecepcionSolicitud: $fechaRecepcionSolicitud
    fkTipoAntecedente: $fkTipoAntecedente
    antecedenteMedIdGD: $antecedenteMedIdGD
    fkEventoAdministrativoDeclarado: $fkEventoAdministrativoDeclarado
    fechaEventoAdministrativo: $fechaEventoAdministrativo
    rutPrestadorInstitucional: $rutPrestadorInstitucional
    razonSocialPrestador: $razonSocialPrestador
    rutMedicoTratante: $rutMedicoTratante
    nombreMedicoTratante: $nombreMedicoTratante
    especialidadMedicoTratante: $especialidadMedicoTratante
    especialidadOtroMedicoTratante: $especialidadOtroMedicoTratante
    tratamiento: $tratamiento
    declaraHospitalizacion: $declaraHospitalizacion
    fechaHospitalizacionDesde: $fechaHospitalizacionDesde
    fechaHospitalizacionHasta: $fechaHospitalizacionHasta
    rutInstitucionHopsitalizacion: $rutInstitucionHopsitalizacion
    razonSocialInstitucionHopsitalizacion: $razonSocialInstitucionHopsitalizacion
    declaraTraslado: $declaraTraslado
    origenDestinoTraslado: $origenDestinoTraslado
    destinoTraslado: $destinoTraslado
    fkEstadoSolicitudIsapre: $fkEstadoSolicitudIsapre
    fechaSolicitudIsapre: $fechaSolicitudIsapre
    fechaAutorizacionSoliciut: $fechaAutorizacionSoliciut
    fkCausalRechazo: $fkCausalRechazo
    fechaCierre: $fechaCierre
    fkCausalCierre: $fkCausalCierre
    beneficiarioNotificado: $beneficiarioNotificado
    fechaNotificacionBeneficiario: $fechaNotificacionBeneficiario
    fkTipoResolucionBeneficiario: $fkTipoResolucionBeneficiario
    fechaResolucionBeneficiario: $fechaResolucionBeneficiario
    declaraUrgenciaVital: $declaraUrgenciaVital
    sucursal: $sucursal
    createdUser: $createdUser
    userEmail: $userEmail
    updatedUser: $updatedUser
    tipoCaso: $tipoCaso
    luatPrestadorInstitucional:$luatPrestadorInstitucional
    direccionPrestadorInstitucional: $direccionPrestadorInstitucional
    luatHospitalizacion: $luatHospitalizacion
    direccionHospitalizacion: $direccionHospitalizacion
    nombrePaciente:$nombrePaciente,
    apellido1Paciente:$apellido1Paciente,
    apellido2Paciente:$apellido2Paciente
  )
}
`;

export const historialBonos = gql`
query(
  $rut:String!
  $orden:String!
  $limit: Float!
  $offset:Float!
){
  getBonos(rut:$rut,orden:$orden,limit:$limit,offset:$offset){
      idCasoCanasta
      codigoCanasta
      folio
      descripcionCanasta
      descripcionPrestacion
      prestacion
      fechaGasto
      folioBono
      montoTotalBoleta
      montoTotalPrestacion
      montoBonificado
      copago
      periodicidad
      tPeriodicidad
      estado
      fechaInicio
      fechaFin
      tipoDocumento
  }
}
`

export const ObtenerHistorial = gql`
query ($solicitud: String!) {
  getLogAcciones(solicitud: $solicitud) {
    solicitud
    accion
    descripcion
    FechaIngreso
    LoginAutorizacion
  }
}`;

export const getLocalidades = gql`
query{
  getLocalidades(opcion:1){
      idReg
      nomReg
      ciudades{
          idCiu
          nomCiu
          comunas{
              idCom
              nomCom
          }
      }
  }
}
`;

export const dataUsuario = gql`
query(
  $usuario:String!
  $token:String!
  ){
  getuserData(usuario:$usuario,token:$token){
      mail
      sucursal
  }
}
`;

export const eliminahitosasignados = gql`
mutation(
  $idHito:Float!
  $usuario:String!
  $sucursal:String!
){
  eliminahitosAsignados(idHito:$idHito,usuario:$usuario,sucursal:$sucursal)
}
`;

export const getcomunasxregion = gql`
query{
  getLocalidades(opcion:2){
      idReg
      nomReg
      comunas{
          idCom
          nomCom
      }
      }
  }

`;

export const validacanastaconbonos = gql`
query(
  $idCanastaAsignada:Float!
  $rut:String!
){
  validaCanastaconBonos(idCanastaAsignada:$idCanastaAsignada,rut:$rut){
      respuesta
      FInicio
      FTermino
  }
}
`;

export const editahitosasignados = gql`
mutation(
  $idHito:Float!
  $usuario:String!
  $sucursal:String!
  $FHitoInicio:DateTime
  $FHitoTermino:DateTime
){
  editahitosAsignados(
      idHito:$idHito,
      usuario:$usuario,
      sucursal:$sucursal,
      FHitoInicio:$FHitoInicio,
      FHitoTermino:$FHitoTermino
      )
}
`;

export const filtroorigen = gql`
query{
  getOrigenSGC{
      id
      descripcion
  }
}
`;

export const traerform = gql`
query($plantilla:String!){
  traerPlantilla(plantilla:$plantilla)
}
`;

export const exportavdmn = gql`
query
($patologia: Float,
  $codcanasta: String,
  $formulaActiva: String,
  $codigoSB: Float,
  $descripcion: String,
  $estado: Float,
  $arancel: Float
  $eliminado: Boolean!,
  $order: String!, 
  $dir: String!){
  exportaVademecumZipzonSt(
    patologia: $patologia,codcanasta: $codcanasta, formulaActiva: $formulaActiva, 
    codigoSB: $codigoSB, descripcion: $descripcion, estado: $estado, 
    order: $order, dir: $dir, arancel: $arancel, eliminado: $eliminado)
}
`;

export const importVdmc = gql`
mutation
(
    $data:String!
    $dataDel: String!
    $creados:Float!
    $actualizados:Float!
    $eliminados:Float!
    $usuario:String!
    $sucursal:String!
){
    registravademecum(
        data:$data,
        dataDel: $dataDel,
        creados:$creados,
        actualizados:$actualizados,
        eliminados:$eliminados,
        usuario:$usuario,
        sucursal:$sucursal)
}
`;

export const historialVdmc = gql`
query
(
    $limit:Float!
    $offset:Float!
    ){
    historialVademecum(limit:$limit,offset:$offset){
        totalVDMN
        totallogVDMN
        logs{
            descripcion
            FechaIngreso
            SucursalIngreso
            LoginAutorizacion
        }
    }
}
`;

export const actualizacachevdmn = gql`
query{
  actualizacacheVDMN
}
`;

export const guardaME = gql`
mutation
(
   $rut:String!
   $canasta:String!
   $codigoME:Float!
   $caso:Float!
   $fechaInicio:String!
   $fechaTermino:String
   $nombreMed:String!
   $usuario:String
   $sucursal:String
){
    guardaME(rut:$rut,canasta:$canasta,codigoME:$codigoME,caso:$caso,fechaInicio:$fechaInicio,fechaTermino:$fechaTermino,nombreMed:$nombreMed,usuario:$usuario,sucursal:$sucursal)

}
`

export const buscamed = gql`
query
($codigo:Float!){
    buscamedicamentos(codigo:$codigo)
}
`

export const dataUsuarioMe = gql`
query
($rut:String!){
    dataUsuarioME(rut:$rut){
        rut
        nombre
        casos{
            fkFun
            fechaAutorizacion
            canastas
            fkProblemaSalud
            GlosaSis
        }
        message{
          rut
          finVigencia
      }
    }
}
`

export const listamed = gql`
query{
  getmedicamentos{
      sku
      descripcion
  }
}
`

export const tablaquery = gql`
query(
  $rut: String
  $canasta: String
  $codigoME: Float
  $nombreMed: String
  $limit: Float
  $offset: Float
  $order: String
  $dir: String 
  $activo: Boolean!
){
 getmedicamentosExcepcion(
      rut:$rut,
      canasta:$canasta,
      codigoME:$codigoME,
      nombreMed:$nombreMed,
      limit:$limit,
      offset:$offset,
      order:$order,
      dir:$dir,
      activo: $activo
 ){
     total
     ME{
   id
   rut
   nombrePaciente
   caso
   fechaAutorizacion
   canasta
   descripcionCanasta
   fechaInicio
   fechaTermino
   sku
   descripcionmed 
   fechaCreacion
   usuarioCreador 
   estado
     } 
 } 

}
`

export const editaME = gql`
mutation(
  $id:Float!
  $fechaInicio:DateTime!
  $fechaTermino:DateTime
  $usuario:String
  $sucursal:String
){
  editaFechaME(id:$id,fechaInicio:$fechaInicio,fechaTermino:$fechaTermino,usuario:$usuario,sucursal:$sucursal)
}
`

export const eliminaME = gql`
mutation(
  $id:Float!
  $usuario:String!
  $sucursal:String!
){
  eliminaME(id:$id,usuario:$usuario,sucursal:$sucursal)
}
`

export const cargaMasivaME = gql
  `
mutation(
  $dataME:String!
  $datamedicamento:String!
  $creados:Float!
  $usuario:String!
  $sucursal:String!
){registraME(dataME:$dataME,datamedicamento:$datamedicamento,creados:$creados,usuario:$usuario,sucursal:$sucursal)
}
`

export const exportaME = gql`
query(
  $rut: String
  $canasta: String
  $codigoME: Float
  $nombreMed: String
  $activo: Boolean!
){
 exportaME(
      rut:$rut,
      canasta:$canasta,
      codigoME:$codigoME,
      nombreMed:$nombreMed,
      activo: $activo
 )
}
`

export const cargaPS = gql`
mutation(
  $dataPS:String!
  $creados:Float!
  $usuario:String!
  $sucursal:String!

){
  registraPS(dataPS:$dataPS,creados:$creados,usuario:$usuario,sucursal:$sucursal)
}
`
export const cargaexcelPS = gql`
mutation(
  $id_log:Float!
  $base64:String!
  $nombreArchivo:String!
){
  guardaExcelPS(id_log:$id_log,base64:$base64,nombreArchivo:$nombreArchivo)
}
`

export const editaPS = gql`
mutation(
  $id:Float!
  $glosa:String
  $descripcion:String
  $fechaInicio:DateTime
  $fechaTermino:DateTime
  $usuario:String!
  $sucursal:String!
){
  editaPS(
      id:$id,
      glosa:$glosa,
      descripcion:$descripcion,
      fechaInicio:$fechaInicio,
      fechaTermino:$fechaTermino,
      usuario:$usuario
      sucursal:$sucursal
  )

}
`

export const eliminaPS = gql`
mutation(
  $id:Float!
  $usuario:String!
  $sucursal:String!
){
  eliminaPS(
      id:$id,
      usuario:$usuario
      sucursal:$sucursal
  )
}
`
export const getPSbyfilter = gql`
query(
  $codigo: Float
  $glosa: String
  $arancel: Float
  $estado: Float
  $order: String
  $dir: String
  $offset: Float
  $limit: Float,
  $eliminado: Boolean!
  ){
      getProblemasSaludXfilter(codigo:$codigo,glosa:$glosa,arancel:$arancel,estado:$estado,order:$order,dir:$dir,offset:$offset,limit:$limit, eliminado: $eliminado){
          total
          FultimaCarga
          PS{
              id
              Codigo
              GlosaSis
              Arancel
              Genero
              estado
              InicioVigencia
              TerminoVigencia
              InicioEdad
              TerminoEdad
              TipoRangoEdad
              Descripcion
          }
      }
  }
`

export const logPS = gql`
query(
  $id:String!
){
  logPS(id:$id){
      total
      logs{
          LoginAutorizacion
          FechaIngreso
          descripcion
      }
      
  }
}
`

export const logcargaPS = gql`
query(
  $offset: Float
  $limit: Float
){
  logcargaPS(offset:$offset,limit:$limit){
      total
      logs{
          LoginAutorizacion
          FechaIngreso
          descripcion
      }
      
  }
}`

export const exportaPS = gql`
query(
  $codigo: Float
  $glosa: String
  $arancel: Float
  $estado: Float,
  $eliminado: Boolean!
  ){
      exportaPS(
          codigo:$codigo,
          glosa:$glosa,
          arancel:$arancel,
          estado:$estado,
          eliminado: $eliminado)
  }
`

export const aranceles = gql`
query{
  getAranceles{
      arancel
      vigente
  }
}
`
export const tablaVDMC = gql`
query(
  $patologia: Float,
  $codcanasta: String,
  $formulaActiva: String,
  $codigoSB: Float,
  $descripcion: String,
  $estado: Float,
  $arancel: Float,
  $eliminado: Boolean,
  $limit: Float!, 
  $offset: Float!, 
  $order: String!, 
  $dir: String!){
  getVDMCbyFilter(patologia: $patologia,codcanasta: $codcanasta, formulaActiva: $formulaActiva, 
    codigoSB: $codigoSB, descripcion: $descripcion, estado: $estado, limit: $limit, 
    offset: $offset, order: $order, dir: $dir, arancel: $arancel, eliminado: $eliminado){
      total
      VDMC{
          id
          patologia
          canasta
          formulaActiva
          codigoSB
          descripcion
          arancel
          vigencia
          inicioVigencia
          finVigencia
      }
  }
}`;

export const eliminaRegVdmc = gql`
mutation(
  $id: Float!,
  $usuario: String!,
  $sucursal: String!
){
  eliminaVDMC(id: $id, usuario: $usuario, sucursal: $sucursal)
}`;

export const logVdmc = gql`
query(
  $id: String!,
  $limit: Float,
  $offset: Float
){
  logVDMC(id: $id, limit: $limit, offset:$offset){
      logs{
        LoginAutorizacion
        FechaIngreso
        descripcion
    }
      total
  }
}`;

export const cargaexcelVDMC = gql`
mutation(
  $id_log:Float!
  $base64:String!
  $nombreArchivo:String!
){
  guardaExcelVDMC(id_log:$id_log,base64:$base64,nombreArchivo:$nombreArchivo)
}
`

export const cargaLEP = gql`
mutation(
  $prestxcan:String!
  $creados:Float!
  $usuario:String!
  $sucursal:String!

){
  registraPrestaciones(prestxcan:$prestxcan,creados:$creados,usuario:$usuario,sucursal:$sucursal)
}
`

export const cargaexcelLEP = gql`
mutation(
  $id_log:Float!
  $base64:String!
  $nombreArchivo:String!
){
  guardaExcelLEP(id_log:$id_log,base64:$base64,nombreArchivo:$nombreArchivo)
}
`

export const tablaLep = gql`
  query ($problemaSalud: Float, $nombreProblemaSalud: String, $tipoPrestacion: String, $prestacion: String, $descripcion: String, $AH: String, $decreto: Float, $estado: Float, $order: String!, $dir: String!, $limit: Float, $offset: Float, $eliminado: Boolean!) {
    getLepByFilter(
        problemaSalud: $problemaSalud
        nombreProblemaSalud: $nombreProblemaSalud
        tipoPrestacion: $tipoPrestacion
        prestacion: $prestacion
        descripcion: $descripcion
        AH: $AH
        decreto: $decreto
        estado: $estado
        order: $order
        dir: $dir
        limit: $limit
        offset: $offset
        eliminado: $eliminado
    ) {
        lastUpdate {
            LoginAutorizacion
            FechaIngreso
            descripcion
        }
        totalLEP
        LEP {
            id
            problemaSalud
            GlosaSis
            descCanasta
            canasta
            tipoPrestacion
            prestacion
            der_Pab
            descripcion
            AH
            copagoGes
            periodicidad
            tPeriodicidad
            decreto
            codPrestacionMinsal
            presAdicionalEsencial
            arancelMinsal
            estado
        }
    }
  }
`;

export const exportaLEP = gql`
  query ($problemaSalud: Float, $nombreProblemaSalud: String, $tipoPrestacion: String, $prestacion: String, $descripcion: String, $AH: String, $decreto: Float, $estado: Float, $order: String!, $dir: String!, $eliminado: Boolean!) {
    exportaLEP(
        problemaSalud: $problemaSalud
        nombreProblemaSalud: $nombreProblemaSalud
        tipoPrestacion: $tipoPrestacion
        prestacion: $prestacion
        descripcion: $descripcion
        AH: $AH
        decreto: $decreto
        estado: $estado
        order: $order
        dir: $dir
        eliminado: $eliminado
    )
  }
`;

export const historialCargaLEP = gql`
  query(
      $limit: Float!
      $offset: Float!
    ){
    historialCargaLEP(
          limit:$limit,
          offset:$offset
    ){
        totalLogLEP
        logs{
            LoginAutorizacion
            FechaIngreso
            descripcion
        }
    }
  }
`;

export const eliminaRegLEP = gql`
mutation(
  $id: Float!,
  $usuario: String!,
  $sucursal: String!
){
  eliminaLEP(id: $id, usuario: $usuario, sucursal: $sucursal)
}`;

export const logLEP = gql`
query(
  $id: String!,
  $limit: Float,
  $offset: Float
){
  logLEP(id: $id, limit: $limit, offset:$offset){
      logs{
        LoginAutorizacion
        FechaIngreso
        descripcion
    }
      total
  }
}`;

export const historialCargaMED = gql`
  query(
      $limit: Float!
      $offset: Float!
    ){
      historialCargaMED(
          limit:$limit,
          offset:$offset
    ){
        totalLog
        logs{
            LoginAutorizacion
            FechaIngreso
            descripcion
        }
    }
  }
`;

export const logMED = gql`
query(
  $id: String!,
  $limit: Float,
  $offset: Float
){
  logMED(id: $id, limit: $limit, offset:$offset){
      logs{
        LoginAutorizacion
        FechaIngreso
        descripcion
    }
      total
  }
}`;

export const guardaExcelMEGD = gql`
mutation(
  $id_log:Float!
  $base64:String!
  $nombreArchivo:String!
){
  guardaExcelMEGD(id_log:$id_log,base64:$base64,nombreArchivo:$nombreArchivo)
}
`
export const getAllPrest = gql`
query{
  getAllPrestadores{
     internalRolId
     rut
     razonSocial
     nombreCalle
     numeroCalle
     region
     ciudad
     comuna
     vigenciaFinal
     vigenciaInicial
     luat
  }
 }
`;

export const cargaCanastas = gql`
mutation(
  $canastas:String!
  $creados:Float!
  $usuario:String!
  $sucursal:String!

){
  registraCanastas(canastas:$canastas,creados:$creados,usuario:$usuario,sucursal:$sucursal)
}
`

export const cargaexcelCanastas = gql`
mutation(
  $id_log:Float!
  $base64:String!
  $nombreArchivo:String!
){
  guardaExcelCanastas(id_log:$id_log,base64:$base64,nombreArchivo:$nombreArchivo)
}
`
export const historialCargaCan = gql`
  query(
      $limit: Float!
      $offset: Float!
    ){
      historialCargaCan(
          limit:$limit,
          offset:$offset
    ){
      totalCanastas
        logs{
            LoginAutorizacion
            FechaIngreso
            descripcion
        }
    }
  }
`;

export const actualizaCacheME = gql `
  query{
    actualizacacheME
  }
`;

export const exportaBandeja = gql `
query (
  $order:String!,
  $dir:String!
  $offset: Float, 
  $limit: Float
  $fkFun: Int
  $tipoCaso: String
  $rutPaciente: String
  $nombrePaciente: String
  $fkEstadoSolicitudIsapre: Int
  $fechaInicio: DateTime
  $fechaFin: DateTime
  $fkProblemaSalud: Int
  $origen: Int
  $numSolicitudOrigen: String
) {
  exportSGC(
    order:$order,
    dir:$dir,
    offset: $offset, 
    limit: $limit,
    fkFun: $fkFun
    tipoCaso: $tipoCaso
    rutPaciente: $rutPaciente
    nombrePaciente: $nombrePaciente
    fkEstadoSolicitudIsapre: $fkEstadoSolicitudIsapre
    fechaInicio: $fechaInicio
    fechaFin: $fechaFin
    fkProblemaSalud: $fkProblemaSalud
  	origen: $origen
  	numSolicitudOrigen: $numSolicitudOrigen
  ) {
    total
    solicitudes{
        fkFun    
        estadoHito{
            id
            descripcion
        }
        tipoCaso
        rutPaciente
        numSolicitudOrigen
        nombrePaciente
        apellido1Paciente
        apellido2Paciente
        fkProblemaSalud
        diagnosticoCaec
        nombrepat {
        GlosaSis
        }
        fechaRecepcionSolicitud
        fechaSolicitudIsapre
        fkEstadoSolicitudIsapre
        declaraHospitalizacion
        razonSocialInstitucionHopsitalizacion
        nombreRepresentante
        origen
        origenR
        apellido1Representante
        apellido2Representante
        createdUser
        arancel
        fechaCierre
        motivoCierre
        prestadores{
            total
            prestadores{
                prestador
                nombrePrestador
                direccionPrestador
                transitorio
                tipoPrestador
            }
        }
        cie
        fkCie10
        plan
        fVigenciaBeneficiario
    }
  }
}
`;