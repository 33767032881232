import "regenerator-runtime";
import { Workbook } from 'exceljs';
import { Buffer } from 'buffer';

export async function buildTxtXlsx(mm, anio, parsedInfo) {
    if (typeof parsedInfo === 'object') {
        const txtData = parsedInfo.txt;
        const txtb64 = txtData.toString('base64');
    
        const xlsxData = parsedInfo.xlsx;
        const workbook = new Workbook();
    
        workbook.creator = 'GESCAEC';
        // workbook.lastModifiedBy = '';
        workbook.created = new Date(parseInt(anio), parseInt(mm), 1);
        workbook.modified = new Date(parseInt(anio), parseInt(mm), 1);
        workbook.lastPrinted = new Date(parseInt(anio), parseInt(mm), 1);
    
        workbook.addWorksheet('Información');
        const worksheet = workbook.getWorksheet(1);
    
        // add new rows and return them as array of row objects
        worksheet.addRows(xlsxData);
        const xlsxBuffer = await workbook.xlsx.writeBuffer();
        const xlsxb64 = Buffer.from(xlsxBuffer).toString('base64');

        return {
            "txtb64": txtb64,
            "xlsxb64": xlsxb64 
        }
    } else {
        console.error('No se puede construir el reporte. Ingrese la información correcta.');
        return null;
    }
}