import { useState } from "react";
import { Opciones } from "./Opciones";
import { SecondaryButton } from "../../../../components/Buttons/Botones";
import {
  rechazo,
  fechaCierreCausal,
  anularCaso,
  reabrirCaso,
} from "../../../../utils/queries";
import {
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  RadioGroup,
  TextareaAutosize,
  Typography
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import Mensajero from "../../../../components/Snackbar/Mensajero";
import { useMutation } from "@apollo/client";
import { VarianteTexto1, VarianteTexto2, VarianteTexto3 } from "./TextosDialogos";
import BotonCarga from "../../../../components/Buttons/BotonCarga";
import { VerificaCaso } from "../../../IngresoPaciente/FormularioIngreso/components/VerificaCaso";

export function CambioEstadoCaso({ refetch, campos, dataDuplicado, isInvalid, ...props }) {
  const { tipoCaso } = campos
  const diagnostico = tipoCaso === 'GES' ? campos.nombrepat.GlosaSis : campos.diagnosticoCaec
  const [opcion, setOpcion] = useState(null);
  const [textoIngresado, setTextoIngresado] = useState("");
  const { accounts } = useMsal();
  const [snackBar, setSnackBar] = useState({
    abrir: false,
    mensaje: "",
    severidad: "info",
  });
  const [rechazarCaso, { loading: cargaRechazar }] = useMutation(rechazo);
  const [AnularCaso, { loading: cargaAnular }] = useMutation(anularCaso);
  const [cerrarCaso, { loading: cargaCerrar }] = useMutation(fechaCierreCausal);
  const [reabrir, { loading: cargaReabrir }] = useMutation(reabrirCaso);

  const cambiafolio = (folio) => {
    sessionStorage.setItem('fkFun', folio)
  }

  function activaMensajero(msj, sev) {
    setSnackBar({
      abrir: true,
      mensaje: msj,
      severidad: sev,
    })
  }
  const cerrarModal = () => {
    props.setModal(false)
    setOpcion(null)
    setTextoIngresado("")
  }

  function cambioEstadoCaso() {
    switch (props.tipo) {
      case 1:
        rechazarCaso({
          variables: {
            fkCausalRechazo: parseInt(opcion),
            folio: props.folio,
            usuario: accounts[0].name,
            correo: props.correo,
            sucursal: props.sucursal,
            comentarioRechazo: textoIngresado
          },
          onCompleted: () => {
            setOpcion(null);
            setTextoIngresado("");
            activaMensajero("Se rechazó el caso exitosamente", "success");
            props.setModal(false);
            refetch();
          },
          onError: (error) => {
            activaMensajero("Hubo un problema al rechazar el caso. Por favor, intentelo nuevamente", "error");
          }
        })
        break;
      case 2:
        AnularCaso({
          variables: {
            folio: props.folio,
            comentarioAnulacion: textoIngresado,
            usuario: accounts[0].name,
            correo: props.correo,
            sucursal: props.sucursal,
            isInvalid: isInvalid
          },
          onCompleted: () => {
            setOpcion(null);
            setTextoIngresado("");
            activaMensajero("Se anuló el caso exitosamente", "success");
            props.setModal(false);
            refetch();
          },
          onError: () => {
            activaMensajero("Hubo un problema al anular el caso. Por favor, intentelo nuevamente", "error");
          }
        })
        break;
      case 3:
        cerrarCaso({
          variables: {
            fkCausalCierre: parseInt(opcion),
            folio: props.folio,
            comentario: textoIngresado,
            usuario: accounts[0].name,
            correo: props.correo,
            sucursal: props.sucursal,
            pacienteVigente: !isInvalid
          },
          onCompleted: () => {
            setOpcion(null);
            setTextoIngresado("");
            activaMensajero("Se cerró el caso exitosamente", "success");
            props.setModal(false);
            refetch();
            const reloadMilestonesButton = document.getElementById('recargaTablaHitos');
            if (reloadMilestonesButton) reloadMilestonesButton.click();
          },
          onError: (error) => {
            activaMensajero("Hubo un problema al Cerrar el caso. Por favor, intentelo nuevamente", "error");
          }
        })
        break;
      case 4:
        reabrir({
          variables: {
            fkCausalReapertura: parseInt(opcion),
            folio: props.folio,
            comentarioReapertura: textoIngresado,
            usuario: accounts[0].name,
            correo: props.correo,
            sucursal: props.sucursal,
          },
          onCompleted: () => {
            setOpcion(null);
            setTextoIngresado("");
            activaMensajero("Se re-abrió el caso exitosamente", "success");
            props.setModal(false);
            refetch();
          },
          onError: (error) => {
            console.log(error)
            activaMensajero("Hubo un problema al re-abrir el caso. Por favor, intentelo nuevamente", "error");
          }
        })
        break;
      default: return null;
    }
  }

  return (
    <div data-testid="dialogsCierre">
      <Mensajero data={snackBar} setState={setSnackBar} />
      <VerificaCaso
        ps={dataDuplicado.psFolio}
        psNombre={diagnostico}
        folio={dataDuplicado.duplicado}
        modalCaso={props.modalCaso} //abrir cerrar
        setModalCaso={props.setModalCaso} //cerrar modal
        preing={dataDuplicado.preing}
        reabrir={true}// si es preingresado una u otra
        setFkFun={cambiafolio} // 
      />
      <Dialog
        className="dialogModalBig"
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.modal}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <DialogTitle className="tituloModal" sx={{ padding: "30px 0", width: "700px" }}>
          ¿Estás seguro que deseas <VarianteTexto1 tipo={props.tipo} /> el caso?
          <Typography sx={{ padding: "0 15px", textAlign: "center" }}>
            <VarianteTexto3 tipo={props.tipo} /> el Caso Nº {props.folio}
            <br/>{props.tipo === 3 ? '(Se cerrarán las canastas, prestadores e hitos asociados)' : null}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="end"
            alignItems="center"
          >
            <Grid
              container
              spacing={2}
              direction="row"
              sx={{ padding: "0 20px" }}
            >
              <Grid item xs={12} hidden={props.tipo !== 2 ? false : true}>
                Selecciona un motivo de <VarianteTexto2 tipo={props.tipo} />:
              </Grid>

              <Grid item xs={12}>
                <FormControl className="radiosGroup" hidden={props.tipo !== 2 ? false : true}>
                  <RadioGroup
                    data-testid="dialogCierreSelect"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={opcion}
                    disabled={cargaRechazar || cargaAnular || cargaCerrar || cargaReabrir}
                    onChange={(opt) => {
                      setOpcion(parseInt(opt.target.value));
                    }}
                  >
                    <Opciones tipo={props.tipo} tipoCaso={props.tipoCaso} disabled={cargaRechazar || cargaAnular || cargaCerrar || cargaReabrir} />
                  </RadioGroup>
                </FormControl>
                <Collapse
                  in={
                    props.tipo === 2 ||
                      (props.tipoCaso === "GES" && props.tipo === 1 && opcion === 6) ||
                      (props.tipoCaso === "GES" && props.tipo === 3 && opcion === 8) ||
                      (props.tipoCaso === "CAEC" && props.tipo === 1 && opcion === 3) ||
                      (props.tipoCaso === "CAEC" && props.tipo === 3 && opcion === 5) ||
                      (props.tipo === 4 && opcion === 3)
                      ? true : false}
                  unmountOnExit
                >
                  <Grid item xs={12}>
                    Observaciones
                    <TextareaAutosize
                      minRows={3}
                      maxRows={6}
                      maxLength={300}
                      aria-label="maximum height"
                      placeholder="Ingresa una observación"
                      data-testid="comentarioRechazo"
                      value={textoIngresado}
                      disabled={cargaRechazar || cargaAnular || cargaCerrar || cargaReabrir}
                      onChange={(e) => {
                        setTextoIngresado(e.target.value);
                      }}
                    />
                    <label className="labelTextarea">{(textoIngresado && textoIngresado.length) || 0}/300</label>
                  </Grid>
                </Collapse >

              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "0 0 20px" }}
          >
            <SecondaryButton
              variant=" text"
              color="default"
              data-testid="cerrarRechazo"
              disabled={cargaRechazar || cargaAnular || cargaCerrar || cargaReabrir}
              onClick={cerrarModal}
              sx={{ marginRight: "30px" }}
            >
              Volver
            </SecondaryButton>
            <BotonCarga
              testid="confirmarRechazo"
              label="Confirmar"
              toDo={cambioEstadoCaso}
              loading={cargaRechazar || cargaAnular || cargaCerrar || cargaReabrir}
              variant='secondary'
              disabled={
                (props.tipo !== 2 && !opcion) ||
                  (props.tipo === 1 && props.tipoCaso === "GES" && parseInt(opcion) === 6 && textoIngresado === "") ||
                  (props.tipo === 3 && props.tipoCaso === "GES" && parseInt(opcion) === 8 && textoIngresado === "") ||
                  (props.tipo === 1 && props.tipoCaso === "CAEC" && parseInt(opcion) === 3 && textoIngresado === "") ||
                  (props.tipo === 3 && props.tipoCaso === "CAEC" && parseInt(opcion) === 5 && textoIngresado === "") ||
                  (props.tipo === 2 && textoIngresado === "")
                  ? true : false}
            />
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}