import "regenerator-runtime";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format, isValid } from "date-fns";
import { stringify } from "zipson/lib";
import { ajustaFecha, fechaConvertida } from "../../../../utils/fechaUtils";

const lectorArch = new FileReader();

export const generaExcelCanasta = async (datosLEP, mensajero, eliminado) => {
    const textoEliminado = eliminado ? "_Eliminados" : "";
    try {
        const LEP = [];
        LEP.push(["Código GES", "Código Intervención Sanitaria", "Código Canasta Esencial", "Tipo de Prestación", "Código Prestacion",
            "Pabellón", "A/H", "Arancel MINSAL", "Copago GES", "Periodicidad", "Tiempo Periodicidad (días)", "Observación Glosa", "Código prestación Minsal",
        "Prestación Adicional Esencial","Fecha Inicio", "Fecha Termino", "Arancel"]);
        datosLEP.LEP.forEach((prestacionLEP) => {
            const InicioVigencia = fechaConvertida(prestacionLEP.fechaInicio);
            const TerminoVigencia = prestacionLEP.fechaTermino ? fechaConvertida(prestacionLEP.fechaTermino) : null;
            LEP.push([
                prestacionLEP.problemaSalud,
                prestacionLEP.intervencion,
                prestacionLEP.canasta,
                prestacionLEP.tipoPrestacion,
                prestacionLEP.prestacion,
                prestacionLEP.der_Pab,
                prestacionLEP.AH,
                prestacionLEP.arancelMinsal,
                prestacionLEP.copagoGes,
                prestacionLEP.periodicidad,
                prestacionLEP.tPeriodicidad,
                prestacionLEP.obsGlosa,
                prestacionLEP.codPrestacionMinsal,
                prestacionLEP.presAdicionalEsencial,
                InicioVigencia,
                TerminoVigencia,
                prestacionLEP.decreto
            ])
        });
        const excelLEP = new Workbook();
        excelLEP.creator = "GES";
        excelLEP.addWorksheet("DatosLEP");
        const libroCanasta = excelLEP.getWorksheet(1);
        libroCanasta.addRows(LEP);
        excelLEP.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${"LEP"+textoEliminado}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            mensajero({
                abrir: true,
                mensaje: "Descarga generada, revise sus archivos descargados",
                severidad: "success",
            });
            return;
        });
        return;
    } catch (err) {
        mensajero({
            abrir: true,
            mensaje: "No se generó la descarga, intente nuevamente",
            severidad: "error",
        });
    }
}

export const ExcelCanasta = async (excel, mutation, errorMsg) => {
    try {
        const libroCanasta = new Workbook();
        lectorArch.readAsArrayBuffer(excel);
        lectorArch.onload = async ({ target }) => {
            await libroCanasta.xlsx.load(target.result);
            const hoja1 = libroCanasta.worksheets[0];
            preCargaCanasta(excel, hoja1._rows, mutation, errorMsg);
        }
    } catch (err) {
        errorMsg("No se logró procesar el archivo, intente nuevamente.");
    }

}

export const preCargaCanasta = async (excel, data, mutation, errorMsg) => {
    lectorArch.readAsDataURL(excel);
    lectorArch.onload = async ({ target }) => {
        procesaExcelCan(target.result.substr(`data:${excel.type};base64,`.length), data, mutation, errorMsg, excel.name);
    }
}

export const procesaExcelCan = (b64, arrayCanastas, mutation, errorMsg, name) => {
    try {
        const dataCan = [];
        if (arrayCanastas.length <= 1) {
            throw new Error("Archivo sin datos para procesar.");
        }
        if (arrayCanastas.length > 15001) {
            throw new Error("Excede máximo de registros a procesar. (máx 15.000)");
        }
        const createdAt = new Date();
        arrayCanastas.forEach((row, index) => {
            if (index === 0) {
                row._cells.forEach((cell) => {
                    const checkTitulosCan = (a, b) => {
                        if (a !== b) {
                            throw new Error("Verificación de títulos fallida.");
                        }
                    }
                    switch (cell._address) {
                        case "A1": checkTitulosCan(cell._value.model.value, "Intervención Sanitaria")
                            break;
                        case "B1": checkTitulosCan(cell._value.model.value, "Problema de Salud")
                            break;
                        case "C1": checkTitulosCan(cell._value.model.value, "Código Isapre")
                            break;
                        case "D1": checkTitulosCan(cell._value.model.value, "Descripción")
                            break;
                        case "E1": checkTitulosCan(cell._value.model.value, "Código Ministerial")
                            break;
                        case "F1": checkTitulosCan(cell._value.model.value, "Descripción Ministerial")
                            break;
                        case "G1": checkTitulosCan(cell._value.model.value, "Arancel")
                            break;
                        case "H1": checkTitulosCan(cell._value.model.value, "Periodicidad")
                            break;
                        case "I1": checkTitulosCan(cell._value.model.value, "Días")
                            break;
                        case "J1": checkTitulosCan(cell._value.model.value, "Vigencia Desde")
                            break;
                        case "K1": checkTitulosCan(cell._value.model.value, "Vigencia Hasta")
                            break;
                        default: break;
                    }
                });
            }

            if (index > 0) {
                const celda = [];

                row._cells.forEach((cell) => {
                    celda.push({ key: cell._address, value: cell._value.model.value });
                });
                const intSan = celda.find((key) => key.key.includes("A"));
                const problemaSalud = celda.find((key) => key.key.includes("B"));
                const codigo = celda.find((key) => key.key.includes("C"));
                const descripcion = celda.find((key) => key.key.includes("D"));
                const codigoMinisterial = celda.find((key) => key.key.includes("E"));
                const descripcionMinisterial = celda.find((key) => key.key.includes("F"));
                const arancel = celda.find((key) => key.key.includes("G"));
                const periodicidad = celda.find((key) => key.key.includes("H"));
                const dias = celda.find((key) => key.key.includes("I"));
                const vigenciaDesde = celda.find((key) => key.key.includes("J"));
                const vigenciaHasta = celda.find((key) => key.key.includes("K"));
                var fechaInicioCan = null;
                var fechaTerminoCan = null;
                
                if (!(intSan && intSan.value)) {
                    throw new Error("Debe ingresar una Intervención Sanitaria (línea " + (index + 1) + "), favor revisar.");
                }
                if (intSan.value.length > 1) {
                    throw new Error("Intervención Sanitaria sobre el límite (" + intSan.value.length + " > 1) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(problemaSalud && problemaSalud.value && Number.isInteger(parseInt(problemaSalud.value)))) {
                    throw new Error("Problema de Salud no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(codigo && codigo.value)) {
                    throw new Error("Debe ingresar una Código Isapre (línea " + (index + 1) + "), favor revisar.");
                }
                if (codigo.value.length > 255) {
                    throw new Error("Código Isapre sobre el límite (" + codigo.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(descripcion && descripcion.value)) {
                    throw new Error("Debe ingresar una Descripción (línea " + (index + 1) + "), favor revisar.");
                }
                if (descripcion.value.length > 255) {
                    throw new Error("Descripción sobre el límite (" + descripcion.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(codigoMinisterial && codigoMinisterial.value)) {
                    throw new Error("Debe ingresar una Código Ministerial (línea " + (index + 1) + "), favor revisar.");
                }
                if (codigoMinisterial.value.length > 255) {
                    throw new Error("Código Ministerial sobre el límite (" + codigoMinisterial.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(descripcionMinisterial && descripcionMinisterial.value)) {
                    throw new Error("Debe ingresar una Descripción Ministerial (línea " + (index + 1) + "), favor revisar.");
                }
                if (descripcionMinisterial.value.length > 255) {
                    throw new Error("Descripción Ministerial sobre el límite (" + descripcionMinisterial.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(arancel && arancel.value && Number.isInteger(arancel.value))) {
                    throw new Error("Arancel no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(periodicidad && periodicidad.value)) {
                    throw new Error("Debe ingresar una periodicidad (línea " + (index + 1) + "), favor revisar.");
                }
                if (periodicidad.value.length > 255) {
                    throw new Error("Periodicidad sobre el límite (" + periodicidad.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(dias && dias.value && Number.isInteger(dias.value))) {
                    throw new Error("Días periodicidad no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (vigenciaDesde && vigenciaDesde.value) {
                    if (typeof vigenciaDesde.value === 'string' || typeof vigenciaDesde.value === 'object') {
                        if (isValid(vigenciaDesde.value)) {
                            fechaInicioCan = ajustaFecha(vigenciaDesde.value);
                        } else {
                            var InicioVigenciaParts = vigenciaDesde.value.split('/');
                            var mydate = new Date(InicioVigenciaParts[2], InicioVigenciaParts[1] - 1, InicioVigenciaParts[0]);
                            if (isValid(mydate)) {
                                fechaInicioCan = mydate;
                            }
                        }
                    }
                } else {
                    throw new Error("Debe ingresar vigencia desde (línea " + (index + 1) + "), favor revisar.");
                }
                if (fechaInicioCan === null) {
                    throw new Error("Vigencia desde no es fecha válida (línea " + (index + 1) + "), favor revisar.");
                }
                if (vigenciaHasta && vigenciaHasta.value) {
                    if (isValid(vigenciaHasta.value)) {
                        fechaTerminoCan = ajustaFecha(vigenciaHasta.value);
                    } else {
                        var termVig = vigenciaHasta.value.split('/');
                        var testdate = new Date(termVig[2], termVig[1] - 1, termVig[0]);
                        if (isValid(testdate)) {
                            fechaTerminoCan = testdate;
                        } else {
                            throw new Error("Vigencia hasta no válida (línea " + (index + 1) + "), favor revisar.");
                        }
                    }
                }
                
                dataCan.push({
                    intSan: intSan.value,
                    problemaSalud: parseInt(problemaSalud.value),
                    codigo: codigo.value,
                    descripcion: descripcion.value,
                    codigoMinisterial: codigoMinisterial.value,
                    descripcionMinisterial: descripcionMinisterial.value,
                    arancel: parseInt(arancel.value),
                    periodicidad: periodicidad.value,
                    tPeriodicidad: parseInt(dias.value),
                    vigenciaDesde: fechaInicioCan,
                    vigenciaHasta: fechaTerminoCan,
                    createdAt: createdAt,
                    updatedAt: createdAt
                });

            }
        });
        if (dataCan.some((data, index) =>
            dataCan.find((b, indexb) =>
                b.intSan === data.intSan &&
                b.problemaSalud === data.problemaSalud &&
                b.codigo === data.codigo &&
                b.arancel === data.arancel &&
                b.periodicidad === data.periodicidad &&
                b.tPeriodicidad === data.tPeriodicidad &&
                index !== indexb))) {
            errorMsg("Existen registros duplicados, favor revisar líneas.");
        } else {
            mutation(stringify(dataCan), dataCan.length, stringify(b64), name);
        }
    } catch (err) {
        errorMsg(err.message);
    }
}