
import { isContralor, hasJGESCAEC, canReopen } from "../../../../utils/hasPermisions";
import { SelectPersonalizado } from "../../../../components/select/SelectPersonalizado";

export const OpcionesCaso = ({ rol, estadoSI, deshabilitaInputs, selection, selecOpt, cargandoDatos }) => {
    var deshabilitado = false;
    let texto = "Acciones";

    const deshabilitaReabrir = () => {
        if ([7, 10].includes(estadoSI)) {
            if (deshabilitaInputs) {
                return !hasJGESCAEC(rol);
            } else {
                return !canReopen(rol)
            }
        } else {
            return true;
        }
    }

    switch (estadoSI) {
        case 1:
            break;
        case 2:
            break;
        case 3:
            break;
        case 4:
            texto = "Autorizado";
            deshabilitado = true;
            break;
        case 5:
            texto = "Rechazado";
            deshabilitado = true;
            break;
        case 6:
            texto = "Anulado";
            deshabilitado = true;
            break;
        case 7:
            texto = "Cerrado";
            deshabilitado = true;
            break;
        case 9:
            texto = "Re-abierto";
            deshabilitado = true;
            break;
        case 10:
            texto = "Cerrado por sistema";
            deshabilitado = true;
            break;
        default:
            break;
    }

    if (deshabilitaInputs) {
        deshabilitado = true;
    }

    let arrayOpciones = [
        { label: "Autorizar", value: "autorizar", disabled: deshabilitado },
        { label: "Rechazar", value: "rechazar", disabled: deshabilitado },
        { label: "Derivar a GEP", value: "derivar", disabled: true },
        {
            label: "Anular Caso",
            value: "anular",
            disabled:
                estadoSI === 1 && deshabilitaInputs
                    ? false
                    : true,
        },
        {
            label: "Cerrar Caso",
            value: "cerrar",
            disabled:
                estadoSI === 4 || estadoSI === 9 ||
                    (deshabilitaInputs && ![1, 5, 6, 7].includes(estadoSI))
                    ? false
                    : true,
        },
        { label: "Re-abrir Caso", value: "reabrir", disabled: deshabilitaReabrir() },
    ];

    return (
        <SelectPersonalizado
            inputTest="setAcciones"
            value={selection}
            data-testid="selectOpciones"
            setValue={selecOpt}
            options={arrayOpciones}
            placeholder={texto}
            isAcciones={true}
            placeholderHidden={true}
            disabled={isContralor(rol) || cargandoDatos}
        />
    );
}