import { ApolloClient, InMemoryCache, from, createHttpLink } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from '@apollo/client/link/context';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../auth/authConfig";
import { GetAccessToken } from "../auth/azureADTokens";

const abortCon = new AbortController();

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: 2,
    jitter: true
  },
  attempts: {
    max: 2,
    retryIf: (error, _operation) => !!error
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL || '//',
  fetchOptions: {
    mode: 'cors',
    signal: abortCon.signal,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const msalInstance = process.env.JEST_WORKER_ID !== undefined ? '' : new PublicClientApplication(msalConfig);
  // get the authentication token from local storage if it exists
  const token = msalInstance ? await GetAccessToken(msalInstance) : '';  // sessionStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // 'Apollo-Require-Preflight': '*',
      'Authorization': token ? `Bearer ${token}` : "",
      // 'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      // 'Accept': '*/*'
    }
  }
});

export const client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(from([retryLink, httpLink])),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
  }
});