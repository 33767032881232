import "regenerator-runtime";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { format, isValid } from "date-fns";
import { stringify } from "zipson/lib";
import { ajustaFecha, fechaConvertida } from "../../../../utils/fechaUtils";

export const generaExcelPS = async (datosPS, mensajero) => {
    try {
        const problemasSalud = [];
        problemasSalud.push(["Codigo", "GlosaSis", "Descripcion", "Arancel", "InicioVigencia",
            "TerminoVigencia", "InicioEdad", "TerminoEdad", "TipoRangoEdad", "Genero", "Estado", "Fecha de última actualización", "Usuario de última actualización"]);
        datosPS.forEach((psreg) => {
            const InicioVigencia = fechaConvertida(psreg.InicioVigencia);
            const TerminoVigencia = psreg.TerminoVigencia ? fechaConvertida(psreg.TerminoVigencia) : null;
            const fechacreacion = psreg.fechacreacion ? fechaConvertida(psreg.fechacreacion) : null;
            problemasSalud.push([
                psreg.Codigo,
                psreg.GlosaSis,
                psreg.Descripcion,
                psreg.Arancel,
                InicioVigencia,
                TerminoVigencia,
                psreg.InicioEdad,
                psreg.TerminoEdad,
                psreg.TipoRangoEdad,
                psreg.Genero,
                psreg.estado,
                fechacreacion,
                psreg.creador
            ])
        });
        const excelPS = new Workbook();
        excelPS.creator = "GES";
        excelPS.addWorksheet("DatosPS");
        const libroPS = excelPS.getWorksheet(1);
        libroPS.addRows(problemasSalud);
        excelPS.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const name = `${"ProblemasSalud"}_${format(new Date(), "dd-MM-yyyy")}.xlsx`;
            saveAs(blob, name);
            mensajero({
                abrir: true,
                mensaje: "Descarga generada, revise sus archivos descargados",
                severidad: "success",
            });
            return;
        });
        return;
    } catch (err) {
        mensajero({
            abrir: true,
            mensaje: "No se generó la descarga, intente nuevamente",
            severidad: "error",
        });
    }
}

export const ExcelPS = async (excel, mutation, errorMsg) => {
    try {
        const libroPS = new Workbook();
        const lectorArchivo = new FileReader();
        lectorArchivo.readAsArrayBuffer(excel);
        lectorArchivo.onload = async ({ target }) => {
            await libroPS.xlsx.load(target.result);
            const hoja1 = libroPS.worksheets[0];
            preCargaPS(excel, hoja1._rows, mutation, errorMsg);
        }
    } catch (err) {
        errorMsg("No se logró procesar el archivo, intente nuevamente.");
    }

}

export const preCargaPS = async (excel, data, mutation, errorMsg) => {
    const lectorArchivo = new FileReader();
    lectorArchivo.readAsDataURL(excel);
    lectorArchivo.onload = async ({ target }) => {
        procesaDatos(target.result.substr(`data:${excel.type};base64,`.length), data, mutation, errorMsg, excel.name);
    }
}

export const procesaDatos = (b64, arrayPS, mutation, errorMsg, name) => {
    try {
        const datosPS = [];
        if (arrayPS.length <= 1) {
            throw new Error("Archivo sin datos para procesar.");
        }
        if (arrayPS.length > 1001) {
            throw new Error("Excede máximo de registros a procesar. (máx 1.000)");
        }
        arrayPS.forEach((row, index) => {
            if (index === 0) {
                row._cells.forEach((cell) => {
                    const testTitulos = (a, b) => {
                        if (a !== b) {
                            throw new Error("Verificación de títulos fallida.");
                        }
                    }
                    switch (cell._address) {
                        case "A1": testTitulos(cell._value.model.value, "Codigo")
                            break;
                        case "B1": testTitulos(cell._value.model.value, "GlosaSis")
                            break;
                        case "C1": testTitulos(cell._value.model.value, "Descripcion")
                            break;
                        case "D1": testTitulos(cell._value.model.value, "Arancel")
                            break;
                        case "E1": testTitulos(cell._value.model.value, "InicioVigencia")
                            break;
                        case "F1": testTitulos(cell._value.model.value, "TerminoVigencia")
                            break;
                        case "G1": testTitulos(cell._value.model.value, "InicioEdad")
                            break;
                        case "H1": testTitulos(cell._value.model.value, "TerminoEdad")
                            break;
                        case "I1": testTitulos(cell._value.model.value, "TipoRangoEdad")
                            break;
                        case "J1": testTitulos(cell._value.model.value, "Genero")
                            break;
                        default: break;
                    }
                });
            }

            if (index > 0) {
                const celda = [];

                row._cells.forEach((cell) => {
                    celda.push({ key: cell._address, value: cell._value.model.value });
                });
                const Codigo = celda.find((key) => key.key.includes("A"));
                const GlosaSis = celda.find((key) => key.key.includes("B"));
                const Descripcion = celda.find((key) => key.key.includes("C"));
                const Arancel = celda.find((key) => key.key.includes("D"));
                const InicioVigencia = celda.find((key) => key.key.includes("E"));
                const TerminoVigencia = celda.find((key) => key.key.includes("F"));
                const InicioEdad = celda.find((key) => key.key.includes("G"));
                const TerminoEdad = celda.find((key) => key.key.includes("H"));
                const TipoRangoEdad = celda.find((key) => key.key.includes("I"));
                const Genero = celda.find((key) => key.key.includes("J"));
                var inicioVigenciaP = null;
                var terminoVigenciaP = null;

                if (!(Codigo && Codigo.value && Number.isInteger(parseInt(Codigo.value)))) {
                    throw new Error("Código no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(GlosaSis && GlosaSis.value)) {
                    throw new Error("Debe ingresar una Glosa (línea " + (index + 1) + "), favor revisar.");
                }
                if (GlosaSis.value.length > 255) {
                    throw new Error("GlosaSis sobre el límite (" + GlosaSis.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(Descripcion && Descripcion.value)) {
                    throw new Error("Debe ingresar una Descripción (línea " + (index + 1) + "), favor revisar.");
                }
                if (Descripcion.value.length > 1000) {
                    throw new Error("Descripción sobre el límite (" + Descripcion.value.length + " > 1000) (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(Arancel && Arancel.value && Number.isInteger(parseInt(Arancel.value)))) {
                    throw new Error("Arancel no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (InicioVigencia && InicioVigencia.value) {
                    if (typeof InicioVigencia.value === 'string' || typeof InicioVigencia.value === 'object') {
                        if (isValid(InicioVigencia.value)) {
                            inicioVigenciaP = ajustaFecha(InicioVigencia.value);
                        } else {
                            var InicioVigenciaParts = InicioVigencia.value.split('/');
                            var mydate = new Date(InicioVigenciaParts[2], InicioVigenciaParts[1] - 1, InicioVigenciaParts[0]);
                            if (isValid(mydate)) {
                                inicioVigenciaP = mydate;
                            }
                        }
                    }
                } else {
                    throw new Error("Debe ingresar InicioVigencia (línea " + (index + 1) + "), favor revisar.");
                }
                if (inicioVigenciaP === null) {
                    throw new Error("Inicio Vigencia no es fecha válida (línea " + (index + 1) + "), favor revisar.");
                }
                if (TerminoVigencia && TerminoVigencia.value) {
                    if (isValid(TerminoVigencia.value)) {
                        terminoVigenciaP = ajustaFecha(TerminoVigencia.value);
                    } else {
                        var termVig = TerminoVigencia.value.split('/');
                        var testdate = new Date(termVig[2], termVig[1] - 1, termVig[0]);
                        if (isValid(testdate)) {
                            terminoVigenciaP = testdate;
                        } else {
                            throw new Error("Término de Vigencia no válido (línea " + (index + 1) + "), favor revisar.");
                        }
                    }
                }
                if (!(InicioEdad && InicioEdad.value && Number.isInteger(parseInt(InicioEdad.value)))) {
                    throw new Error("InicioEdad no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(TerminoEdad && TerminoEdad.value && Number.isInteger(parseInt(TerminoEdad.value)))) {
                    throw new Error("TerminoEdad no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(TipoRangoEdad && TipoRangoEdad.value && ["A", "M", "D"].indexOf(TipoRangoEdad.value) !== -1)) {
                    throw new Error("TipoRangoEdad no válido (línea " + (index + 1) + "), favor revisar.");
                }
                if (!(Genero && Genero.value)) {
                    throw new Error("Debe ingresar género (línea " + (index + 1) + "), favor revisar.");
                }
                if (Genero.value.length > 255) {
                    throw new Error("Genero sobre el límite (" + Genero.value.length + " > 255) (línea " + (index + 1) + "), favor revisar.");
                }
                datosPS.push({
                    Codigo: parseInt(Codigo.value),
                    GlosaSis: GlosaSis.value.trim(),
                    Descripcion: Descripcion.value.trim(),
                    Arancel: parseInt(Arancel.value),
                    InicioVigencia: inicioVigenciaP,
                    TerminoVigencia: terminoVigenciaP,
                    InicioEdad: parseInt(InicioEdad.value),
                    TerminoEdad: parseInt(TerminoEdad.value),
                    TipoRangoEdad: TipoRangoEdad.value.toUpperCase(),
                    Genero: Genero.value
                });

            }
        });
        if (datosPS.some((data, index) =>
            datosPS.find((b, indexb) =>
                b.Codigo === data.Codigo &&
                b.Arancel === data.Arancel &&
                index !== indexb))) {
            errorMsg("Existen registros duplicados, favor revisar líneas.");
        } else {
            mutation(stringify(datosPS), datosPS.length, stringify(b64), name);
        }
    } catch (err) {
        errorMsg(err.message);
    }
}